import Vue from 'vue'
Vue.directive('auth-hide', {
  bind(el, binding, vnode) {
    const reqPerm = binding.value
    const store = vnode.context.$store

    const stopEnter = e => {
      if (e.which === 13 || e.keyCode === 13 || e.charCode === 13) {
        // trigger was Enter key so we need to stop the enter event from propagating, which stops the form from being submitted
        e.stopPropagation()
        e.preventDefault()
      }
    }

    const set = () => {
      el.classList.remove('auth-hide')
      // hide if the permission has already been determined as 'false' (no permission, pls hide) or if the permission is in the auth list
      if ((typeof reqPerm === 'boolean' && reqPerm) ||
        (typeof reqPerm === 'string' && !store.getters['Auth/hasAuth'](reqPerm))) {
        el.classList.add('auth-hide')

        const type = el.getAttribute('type')
        if (type && type.indexOf('submit') >= 0) {
          // prevent the 'Enter' key from performing the default submit operation for the form (even though the button is hidden, the form will still submit without this)
          Vue.nextTick(() => {
            const form = el.closest('form')
            if (form) {
              form.keypress(stopEnter)
            }
          })

        }
      }
    }

    set()

    window.mpApp.$watch('auth.authorities', () => {
      set()
    })
  },
})
