<!-- eslint-disable-next-line vue/valid-template-root -->
<template />

<script>
import Vue from 'vue'
import Pusher from 'pusher-js'
import gitVersion from '@/gitVersion.js'
import integrations from '@/third-party-integrations/integrations.js'
import { randomNumber } from '@/utils/RandomNumber.js'

export default {
  name: 'PusherComponent',

  data() {
    return {
      // Update checking
      abortController: null,
      showingUpdatePrompt: false,
      // Waits maximum of 10 minutes before actioning the reload request as per ENG-11493
      reloadDelay: randomNumber() * 1000 * 60 * 10,
    }
  },

  beforeCreate() {
    const pusher = new Pusher(this.$appConfiguration.pusher.appKey, {
      cluster: this.$appConfiguration.pusher.cluster,
      forceTLS: true,
    })
    Vue.prototype.$pusher = pusher
  },

  created() {
    const updatesChannel = this.$pusher.subscribe('updates')
    // Listen out for messages telling us there is a new version available. In order to trigger
    // this, from pusher, send a message on the updates channel with an event called new-version
    updatesChannel.bind('new-version', () => {
      // Currently not looking at the message body
      this.newVersionAvailable()
    })

    this.$pusher.connection.bind('connected', () => {
      // Whenever the app (re)connects, we do a check with the server to see if there is a new version
      this.checkForAppUpdate()
    })
  },

  methods: {
    newVersionAvailable() {
      setTimeout(() => {
        if (this.$route.meta.reloadSafe) {
          location.reload(true)
        } else {
          this.$store.commit('needsReload', true)
        }
      }, this.reloadDelay)
    },
    checkForAppUpdate() {
      // If there is already a check in progress, kill it
      if (this.abortController) {
        // Ignored if the fetch is already finished
        try {
          this.abortController.abort()
        } catch (e) {
          // Catch to stop it being sent to Sentry
        }
      }
      let signal = null
      if (typeof AbortController === 'function') {
        this.abortController = new AbortController()
        signal = this.abortController.signal
      }

      // Grab the git hash file from the server so we know the current version hash

      fetch('/self-test/githash.txt', {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain',
        },
        signal,
      })
        .then(response => {
          response
            .text()
            .then(text => {
              // Check it against what we have loaded in the application build we are running
              // Extra check for sanity of length of hash in case permissions are changed and the
              // redirect to /index.html happens
              if (text && text.length < 100 && gitVersion !== text) {
                // console.info('New version of app found', gitVersion, text)
                this.newVersionAvailable()
              }
            })
            .catch(e => {
              integrations.sentry.captureException(e)
            })
        })
        .catch(e => {
          integrations.sentry.captureException(e)
        })
    },
  },
}
</script>
