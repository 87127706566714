// Constant definitions. Any of these definitions that are prefixed with
// "G_" are added to the global mixin, and are therefore available
// throughout the application as part of the data for all components.
import buildProviders from '@/providers/build.js'

/***********************/
/** Globally available */
/***********************/
export const G_PROVISIONING_LOADING = 'LOADING'
export const G_PROVISIONING_DESIGN = 'DESIGN'
export const G_PROVISIONING_DESIGN_DEPLOY = 'DESIGN_DEPLOY'
export const G_PROVISIONING_NEW = 'NEW'
export const G_PROVISIONING_CONFIGURED = 'CONFIGURED'
export const G_PROVISIONING_DEPLOYABLE = 'DEPLOYABLE'
export const G_PROVISIONING_LIVE = 'LIVE'
export const G_PROVISIONING_CANCELLED = 'CANCELLED'
export const G_PROVISIONING_CANCELLED_PARENT = 'CANCELLED_PARENT'
export const G_PROVISIONING_DECOMMISSIONED = 'DECOMMISSIONED'
export const G_PROVISIONING_PENDING_INTERNAL = 'PENDING_INTERNAL'
export const G_PROVISIONING_PENDING_EXTERNAL = 'PENDING_EXTERNAL'
export const G_PROVISIONING_FAILED = 'FAILED'
export const G_DIVERSITY_ZONE_BLUE = 'blue'
export const G_DIVERSITY_ZONE_RED = 'red'
export const G_DIVERSITY_ZONE_PAIR = 'pair'
export const G_DIVERSITY_ZONE_AUTO = 'auto'
export const G_DIVERSITY_ZONE_NONE = 'none'

export const G_PRODUCT_TYPE_MEGAPORT = 'MEGAPORT'
export const G_PRODUCT_TYPE_MCR2 = 'MCR2'
export const G_PRODUCT_TYPE_MVE = 'MVE'
export const G_PRODUCT_TYPE_VXC = 'VXC'
export const G_PRODUCT_TYPE_IX = 'IX'

export const G_CONNECTION_FIELD_VXC = 'associatedVxcs'
export const G_CONNECTION_FIELD_IX = 'associatedIxs'

export const G_FIELD_TYPE_TEXT = 'text'

// Auth email verification status
export const G_AUTH_VERIFICATION_SUCCESSFUL = 'successful'
export const G_AUTH_VERIFICATION_UNSUCCESSFUL = 'unsuccessful'
export const G_AUTH_VERIFICATION_VERIFIED = 'verified'
export const G_AUTH_VERIFICATION_ERROR = 'error'

/********************/
/** Other constants */
/********************/
export const SSO_ENABLED = 'SSO_ENABLED'
export const SSO_OPTIONAL_CAN_LOGIN = 'NATIVE_ONLY'
export const SSO_ENFORCED_NO_ACCESS = 'ACCESS_PROHIBITED'

export const LOGIN_COGNITO = 'cognito'
export const LOGIN_GOOGLE = 'google'

export const PUBLIC_COMPANY_ID = 1269
export const PUBLIC_COMPANY_UID = 'bec27ded-29fb-43dd-8df8-894eb5ca1e98'
export const MEGAPORT_ASN = 133937

export const STATUS_UNPROCESSED = 0
export const STATUS_PROCESSING = 1
export const STATUS_FAILED = 2
export const STATUS_SUCCEEDED = 3

export const PROCESS_STEP_UNAVAILABLE = 'unavailable'
export const PROCESS_STEP_AVAILABLE = 'available'
export const PROCESS_STEP_COMPLETE = 'complete'

export const SERVICE_STATUS_UNKNOWN = 'unknown'
export const SERVICE_STATUS_UP = 'up'
export const SERVICE_STATUS_DOWN = 'down'

export const X_APP = 'portal'

// We need this so we can still show a default list of terms before we call the API and find out the actual list
export const DEFAULT_TERM_PRICES = {
  1: null,
  12: null,
  24: null,
  36: null,
}

// Users in these companies are considered admin
export const MEGAPORT_COMPANY_ID = 1
export const MEGAPORT_LAB_COMPANY_ID = 1153

// Note that in Megalith there is another account type called ROOT, but I don't
// think we ever see that at our end (it's a virtual account)
export const ACCOUNT_TYPE_DIRECT_CUSTOMER = 'DIRECT_CUSTOMER'
export const ACCOUNT_TYPE_PARTNER = 'PARTNER' // Any type of partner
export const ACCOUNT_TYPE_MANAGED_ACCOUNT = 'MANAGED_ACCOUNT'
export const ACCOUNT_TYPE_ADMIN = 'ADMIN' // Megaport internal companies

// Roles
export const ROLE_TYPE_COMPANY_ADMIN = 'Company Admin'
export const ROLE_TYPE_TECHNICAL_ADMIN = 'Technical Admin'
export const ROLE_TYPE_TECHNICAL_CONTACT = 'Technical Contact'
export const ROLE_TYPE_FINANCE = 'Finance'
export const ROLE_TYPE_FINANCIAL_CONTACT = 'Financial Contact'
export const ROLE_TYPE_READ_ONLY = 'Read Only'

// companyConfiguration
export const PARTNER_SUPPORTED = 'PARTNER_SUPPORTED'

// Colors (mainly for map and dashboard widgets)
export const UP_COLOR_START = '#188855'
export const UP_COLOR_END = '#13F347'
export const DOWN_COLOR_START = '#FF0054'
export const DOWN_COLOR_END = '#FF00AA'
export const UNKNOWN_COLOR_START = '#B6B6B6'
export const UNKNOWN_COLOR_END = '#B6B6B6'
export const THIRD_PARTY_COLOR = '#0E0C21'

export const OCCUPIED_GRADIENT_START = '#1AA0FF'
export const OCCUPIED_GRADIENT_END = '#6500D1'
export const GRADIENT_ANGLE = 0.33928677358616427 // Radians => 19.43970017109795 degrees

// Marketplace Bio Max Length
export const BIO_MAX_WORD_COUNT = 200

/*********************/
/****** Vendors ******/
/*********************/
export const ARUBA_VENDOR = 'Aruba'
export const AVIATRIX_VENDOR = 'Aviatrix'
export const CISCO_VENDOR = 'Cisco'
export const FORTINET_VENDOR = 'Fortinet'
export const MERAKI_VENDOR = 'Meraki'
export const PALO_ALTO_VENDOR = 'Palo Alto'
export const VERSA_VENDOR = 'Versa'
export const VMWARE_VENDOR = 'VMware'
export const SIX_WIND_VENDOR = '6WIND'
// Vendor product codes
export const ARUBA_PRODUCT = 'aruba-ecv'
export const AVIATRIX_PRODUCT = 'aviatrix'
export const CISCO_c8000_PRODUCT = 'c8000'
export const CISCO_FTDV_PRODUCT = 'ftdv'
export const FORTINET_PRODUCT = 'fortigate'
export const MERAKI_PRODUCT = 'meraki-vmx'
export const PALO_ALTO_PRODUCT = 'palo-vm'
export const PRISMA_3108_PRODUCT = 'prisma-3108'
export const PRISMA_7108_PRODUCT = 'prisma-7108'
export const SIX_WIND_PRODUCT = '6wind-mve'
export const VERSA_PRODUCT = 'flexvnf'
export const VMWARE_PRODUCT = 'vmware'

// So we can do special handling for SAP connections
export const SAP_DETAILS = {
  companyUid: '7769fd21-9ac5-41ed-aed4-bb4615f1cbe0',
  companyName: 'SAP',
  connectType: 'SAP',
}

export const WASABI_DETAILS = {
  companyUid: 'eaee7551-7b40-468b-b958-34fcf634013a',
  companyName: 'Wasabi Technologies',
  connectType: 'WASABI',
}

export const RACKSPACE_DETAILS = {
  companyUid: 'e09875fd-583a-45b1-a651-c5be51385515',
  companyName: 'Rackspace',
  connectType: 'RACKSPACE',
}

export const AWS_COMPANY = '605cb850-dfb4-4a05-a171-8bf17757b3a2'

export const FRANCEIX_UID = 'ce291fed-90b2-46df-8b39-f87342bd0de7'

// The first of the companyUids will be the one that is used in the target select listing,
// and will be matched with the appropriate icon.
// NOTE: All cloud providers should be listed here, if you want to hide them in quick connect,
// set 'hideQuickConnect' to true
export const CLOUD_ITEMS = [
  ...(buildProviders.disabledFeatures.productMegaportInternet ? [] : [
    {
      connectType: 'TRANSIT',
      title: import.meta.env.VITE_EN_PRODUCTNAME_TRANSIT_VXC,
      megaIcon: 'MegaportInternet',
      hasKeyValidation: false,
      companyUids: [],
    },
  ]),
  ...(buildProviders.disabledFeatures.productIX ? [] : [
    {
      connectType: 'IX',
      title: 'Internet Exchange',
      megaIcon: 'IX',
      hasKeyValidation: false,
      companyUids: [],
    },
  ]),
  {
    connectType: 'OUTSCALE',
    title: '3DS Outscale',
    companyUids: ['1bfc203b-b9a3-46c1-85a3-e4eab6abf439'],
    hasKeyValidation: false,
    hideQuickConnect: true,
  },
  {
    connectType: 'ALIBABA',
    title: 'Alibaba Cloud',
    companyUids: ['182ef3f4-5b74-4adf-9710-6f37a01be434'],
    hasKeyValidation: false,
  },
  ...(buildProviders.disabledFeatures.productAMSIX ? [] : [
    {
      connectType: 'AMSIX',
      title: 'AMS-IX',
      companyUids: ['cd92fac4-7f75-4fb0-bd24-19dcb9ed3e8e'],
      hasKeyValidation: false,
      hideQuickConnect: true,
    },
  ]),
  {
    connectType: ['AWS', 'AWSHC'],
    title: 'Amazon Web Services',
    companyUids: [AWS_COMPANY],
    hasKeyValidation: false,
  },
  {
    connectType: 'GOOGLE',
    title: 'Google Cloud',
    companyUids: ['29ba879b-45c8-48eb-bd97-618d0f20ea04'],
    hasKeyValidation: true,
  },
  {
    connectType: 'IBM',
    title: 'IBM Cloud',
    companyUids: ['dfaa7d3f-450b-4a1e-9026-27cb02c5098d'],
    hasKeyValidation: false,
  },
  {
    connectType: 'AZURE',
    title: 'Microsoft Azure',
    companyUids: ['c40de5ed-4707-4471-a78c-69436de2c505'],
    hasKeyValidation: true,
  },
  {
    connectType: 'NUTANIX',
    title: 'Nutanix',
    companyUids: ['0dda2b1b-6129-4916-adbe-417cefdcaef4', '7752dde1-1cd2-4633-adad-f132a733ff78'],
    hasKeyValidation: true,
    hideQuickConnect: true,
  },
  {
    connectType: 'ORACLE',
    title: 'Oracle Cloud',
    companyUids: ['8ea83603-445c-40c6-94cf-67967a18beb9'],
    hasKeyValidation: true,
  },
  {
    connectType: RACKSPACE_DETAILS.connectType,
    title: RACKSPACE_DETAILS.companyName,
    companyUids: [RACKSPACE_DETAILS.companyUid],
    hasKeyValidation: false,
  },
  {
    connectType: 'SFDC',
    title: 'Salesforce',
    companyUids: ['98ab066a-b945-4369-ad72-ee5a8dc35fbc'],
    hasKeyValidation: false,
  },
  {
    connectType: SAP_DETAILS.connectType,
    title: SAP_DETAILS.companyName,
    companyUids: [SAP_DETAILS.companyUid],
    hasKeyValidation: false, // This is handled using the normal service key processing
  },
  {
    connectType: WASABI_DETAILS.connectType,
    title: WASABI_DETAILS.companyName,
    companyUids: [WASABI_DETAILS.companyUid],
    hasKeyValidation: false, // This is handled using the normal service key processing
  },
]

//
/**
 * This is explicitly *NOT* translated due to the legal language, as directed by email
 * "Portal Localisation - Phase 1 Release" 4/3/21 Indira/Bruce/Melissa/Louise-Chantal/Matthew/Anna
 */
export const LEGAL_PARAGRAPH_MANAGED_PARTNER =
  'This Order constitutes a binding offer to acquire the Services described above and which, if accepted by Megaport, creates a separate agreement incorporating the terms set out in (a) this Order; and (b) the Global Reseller Agreement entered into between you and Megaport.'
export const LEGAL_PARAGRAPH_MANAGED_USER =
  'You are about to place an Order for Services pursuant to terms and pricing agreed between you and a Megaport Partner. If you have any questions or require assistance, please contact your Megaport Partner.'

export const COMPANY_SETUP_LEGALESE =
  'By selecting the check-box, Customer agrees that Services will be acquired on, and Customer will be bound by, the terms of (a) each Service Order submitted by Customer; and (b) where an agreement signed by Customer or its Affiliate relating to the Service(s) exists, that agreement or, otherwise, the <a href="https://www.megaport.com/legal/global-services-agreement/" target="_blank">Global Services Agreement</a> or Global Reseller Agreement, whichever is applicable.'

export const CANCEL_REASONS = [
  { code: 'MOVED_TO_CLOUD_NATIVE_SOL', translationPath: 'cancel-reason.cloud-native-move' },
  { code: 'SERVICE_MOVED', translationPath: 'cancel-reason.moved-dc' },
  { code: 'SERVICE_ORDERED_IN_ERROR', translationPath: 'cancel-reason.order-error' },
  { code: 'PROOF_OF_CONCEPT', translationPath: 'cancel-reason.proof-of-concept' },
  { code: 'PROJECT_ENDED', translationPath: 'cancel-reason.project-ended' },
  { code: 'SWITCHING_PROVIDER', translationPath: 'cancel-reason.switch-provider' },
  { code: 'PRICING', translationPath: 'cancel-reason.pricing' },
  { code: 'PRODUCT_PERFORMANCE', translationPath: 'cancel-reason.product-performance' },
  { code: 'OTHER', translationPath: 'cancel-reason.other' },
]
