<template functional>
  <section class="needs-company-content">
    <h4>{{ parent.$t('checkout.set-up-company') }}</h4>
    <p>{{ parent.$t('checkout.need-setup') }}</p>
    <el-button type="primary"
      name="setup-company"
      @click="parent.$router.push('/company/profile', () => {})">
      {{ parent.$t('checkout.set-up-company') }}
    </el-button>
    <hr class="form-hr">
  </section>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'NeedsCompany',
})
</script>

<style lang="scss" scoped>
.needs-company-content {
  .el-button {
    width: 100%;
  }
  h4 {
    text-align: center;
    background-color: var(--color-info-light-9);
    padding: 10px 0;
  }
}
</style>
