import captureSentryError from '@/utils/CaptureSentryError.js'
import store from '@/store/Store.js'
import integrations from '@/third-party-integrations/integrations.js'

export class SalesforceChat {
  #disabled = import.meta.env.VITE_DISABLE_INTEGRATION_SALESFORCE_CHAT === 'true'

  // We use a promise to delay certain method calls until the integration is ready
  #markReady
  #ready = new Promise(resolve => { this.#markReady = resolve })

  async #readyAndDisabled() {
    // Exit out early in case the integration is disabled
    if (this.#disabled) return true

    await this.#ready
    return this.#disabled
  }

  #getJWT() {
    return store.getters['Auth/accessToken'] || localStorage.getItem('_accessToken')
  }

  #setIdentityToken() {
    const JWT = this.#getJWT()
    if (!JWT) return false
    window.embeddedservice_bootstrap.userVerificationAPI.setIdentityToken({
      identityTokenType: 'JWT',
      identityToken: this.#getJWT(),
    })
    return true
  }

  /**
   * Initialises the Salesforce embedded messaging integration.
   *
   * @param {Object} appConfiguration The environment settings loaded from environment.js
   * @returns {Promise<void>} A promise that resolves when the initialization is complete.
   */
  async init(appConfiguration) {
    if (this.#disabled) return

    // If chat is not configured, log an error and return.
    if (!appConfiguration.salesforceEmbeddedMessaging) {
      integrations.sentry.captureMissingConfigException('Salesforce Chat')
      this.#disabled = true
      return
    }
    const config = appConfiguration.salesforceEmbeddedMessaging

    // Event handler for when the system is ready
    window.addEventListener('onEmbeddedMessagingReady', () => {
      this.#setIdentityToken()
      this.#markReady()
    })

    // Event handler for when the identity token expires
    window.addEventListener('onEmbeddedMessagingIdentityTokenExpired', () => {
      if (!this.#setIdentityToken()) {
        this.logout()
      }
    })

    const script = document.createElement('script')
    script.src = `https://${config.subdomain}.my.site.com/${config.deploymentId}/assets/js/bootstrap.js`
    script.async = true
    script.defer = true
    script.onload = () => {
      try {
        window.embeddedservice_bootstrap.settings.language = 'en_US' // For example, enter 'en' or 'en-US'

        window.embeddedservice_bootstrap.init(
          config.orgId,
          config.deploymentName,
          `https://${config.subdomain}.my.site.com/${config.deploymentId}`,
          {
            scrt2URL: `https://${config.subdomain}.my.salesforce-scrt.com`,
          }
        )
      } catch (err) {
        // In the unlikely event of an error, log it as a sentry error, and set the chat as disabled
        captureSentryError(err)
        this.#disabled = true
        this.#markReady()
      }
    }
    script.onerror = err => {
      // In the unlikely event of an error, log it as a sentry error, and set the chat as disabled
      captureSentryError(err)
      this.#disabled = true
      this.#markReady()
    }

    document.body.appendChild(script)
    await this.#ready
    this.setHidden(true)
  }

  /**
   * Sets up the chat with the provided credentials. The credentials must include a JWT signed using RS256
   *
   * @param {object} credentials - The credentials needed for setup.
   */
  async setup(credentials) {
    if (await this.#readyAndDisabled()) return

    this.#setIdentityToken()

    window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
      t_Email: credentials.email,
    })
    this.setHidden(false)
  }

  /**
   * Sets the visibility of the chat button.
   * @param {boolean} hidden - Indicates whether the chat button should be hidden or shown.
   */
  async setHidden(hidden) {
    if (await this.#readyAndDisabled()) return

    if (hidden) {
      window.embeddedservice_bootstrap.utilAPI.hideChatButton()
    } else {
      window.embeddedservice_bootstrap.utilAPI.showChatButton()
    }
  }

  /**
   * Logs out the user and clears the session.
   */
  async logout() {
    if (await this.#readyAndDisabled()) return

    window.embeddedservice_bootstrap.userVerificationAPI.clearSession()
      .catch(err => {
        captureSentryError(err)
      })
      .finally(() => {
        this.setHidden(true)
      })
  }
}
