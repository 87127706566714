import sdk from '@megaport/api-sdk'

// cap the setTimeout delay for getResults at every 10s
const MAX_FETCH_RESULTS_DELAY = 1000 * 10

// stop polling after 120s
const POLLING_TIMEOUT = 1000 * 120

const coreState = () => ({
  // this is keyed on operationId
  timeoutIds: {},

  ipRoutes: [],
  ipRoutesByIP: null,
  ipRoutesError: null,
  isLoadingIPRoutes: false,

  bgpRoutes: [],
  bgpRoutesByIP: null,
  bgpRoutesError: null,
  isLoadingBGPRoutes: false,

  bgpNeighbourRoutesMap: {},
  bgpNeighbourRoutesErrors: {},
  loadingBGPNeighbourRoutes: {},
})

const actions = {
  fetchRoutes: function({ commit, dispatch }, { context, ...payload }) {
    commit('clearRouteErrors')
    if (context === 'bgp') {
      dispatch('fetchBGPRoutes', payload)
    } else if (context === 'ip') {
      dispatch('fetchIPRoutes', payload)
    }
  },
  fetchIPRoutes: async function({ commit, dispatch }, payload) {
    const success = routes => commit(payload.ipAddress ? 'setIPRoutesByIP' : 'setIPRoutes', routes)
    const loading = isLoading => commit('setIsLoadingIPRoutes', isLoading)
    const failure = error => commit('setIPRoutesError', error)

    try {
      loading(true)

      const { data: operationId } = await sdk.instance
        .mcrRouteDiagnostics(payload.productUid)
        .getIpRoutes(payload.ipAddress)

      dispatch('fetchResults', { operationId, success, loading, failure, ...payload })
    } catch (error) {
      failure(error)
      loading(false)
    }
  },
  fetchBGPRoutes: async function({ commit, dispatch }, payload) {
    const success = routes => commit(payload.ipAddress ? 'setBGPRoutesByIP' : 'setBGPRoutes', routes)
    const loading = isLoading => commit('setIsLoadingBGPRoutes', isLoading)
    const failure = error => commit('setBGPRoutesError', error)

    try {
      loading(true)

      const { data: operationId } = await sdk.instance
        .mcrRouteDiagnostics(payload.productUid)
        .getBgpRoutes(payload.ipAddress)

      dispatch('fetchResults', { operationId, success, loading, failure, ...payload })
    } catch (error) {
      failure(error)
      loading(false)
    }
  },
  fetchResults: async function({ commit, dispatch }, { delay = 1000, pollingDuration = 0, ...payload }) {
    try {
      const operationId = payload.operationId
      const results = await sdk.instance
        .mcrRouteDiagnostics(payload.productUid)
        .getResults(operationId)

      // 202 response means not ready yet, so recursively call this method scaling the delay by 2 until it's ready.
      if (results.status === 202) {
        if (pollingDuration > POLLING_TIMEOUT) {
          commit('clearTimeoutId', operationId)
          // error alert needs a status to render the heading, so we fudge a 408 request timeout
          payload.failure({ message: window.mpApp.$t('mcr-looking-glass.service-loading-timeout'), status: 408 })
          payload.loading(false)
          return
        }

        const nextDelay = Math.min(delay * 2, MAX_FETCH_RESULTS_DELAY)
        const timeoutId = setTimeout(
          () => dispatch('fetchResults', { pollingDuration: pollingDuration + nextDelay, delay: nextDelay, ...payload }),
          delay
        )
        commit('setTimeoutId', { operationId, timeoutId })
      } else {
        commit('clearTimeoutId', operationId)
        payload.success(results.data)
        payload.loading(false)
      }
    } catch (error) {
      payload.failure(error)
      payload.loading(false)
    }
  },
  fetchBGPNeighbourRoute: async function({ commit, dispatch }, payload) {
    const { type, ipAddress } = payload

    const success = routes => commit(`setBGPNeighbourRoutes`, { type, ipAddress, routes })
    const loading = isLoading => commit(`setLoadingBGPNeighbourRoutes`, { type, ipAddress, isLoading })
    const failure = error => commit(`setBGPNeighbourRoutesErrors`, { type, ipAddress, error })

    try {
      loading(true)

      commit('clearBGPNeighbourRoutes', { ipAddress })
      commit('clearBGPNeighbourRoutesError', { ipAddress })

      const { data: operationId } = await sdk.instance
        .mcrRouteDiagnostics(payload.productUid)
        .getBgpNeighbourRoutes(type, ipAddress)

      dispatch('fetchResults', { operationId, success, failure, loading, ...payload })
    } catch (error) {
      failure(error)
      loading(false)
    }
  },
  fetchBGPNeighbourRoutes: function({ dispatch }, payload) {
    dispatch('fetchBGPNeighbourRoute', { type: 'advertised', ...payload })
    dispatch('fetchBGPNeighbourRoute', { type: 'received', ...payload })
  },
  mcrDestroyedCleanup({ commit }) {
    commit('clearTimeoutIds')
    commit('clearRoutes')
  },
  clearRoutes: function({ commit }) {
    commit('clearRoutes')
  },
  clearIPRoutes: function({ commit }, payload) {
    commit('clearIPRoutes', { context: payload })
  },
}

const getters = {
  getRoutes: state => context => {
    const response = state[`${context}Routes`]
    return response
  },
  getRoutesByIP: state => context => {
    const response = state[`${context}RoutesByIP`]
    return response
  },
  getSchema: state => context => {
    const response = state.payloadSchema[context]
    return response
  },
  getBGPNeighbourRoutes: state => ipAddress => {
    const received = state.bgpNeighbourRoutesMap?.[ipAddress]?.received ?? []
    const advertised = state.bgpNeighbourRoutesMap?.[ipAddress]?.advertised ?? []

    return { received, advertised }
  },
  isLoadingBGPNeighbourRoutes: state => ipAddress => {
    const receivedLoading = state.loadingNeighbourRoutes?.[ipAddress]?.received ?? false
    const advertisedLoading = state.loadingBGPNeighbourRoutes?.[ipAddress]?.advertised ?? false

    return receivedLoading || advertisedLoading
  },
  getRouteErrors: state => context => {
    return context === 'ip' ? state.ipRoutesError : state.bgpRoutesError
  },
  getBGPNeighbourRouteErrors: state => ipAddress => {
    const receivedErrors = state.bgpNeighbourRoutesErrors?.[ipAddress]?.received
    const advertisedErrors = state.bgpNeighbourRoutesErrors?.[ipAddress]?.advertised

    const errors = []

    if (receivedErrors) errors.push(receivedErrors)
    if (advertisedErrors) errors.push(advertisedErrors)

    return errors
  },
}

const mutations = {
  setTimeoutId(state, { operationId, timeoutId }) {
    state.timeoutIds[operationId] = timeoutId
  },
  setBGPRoutes(state, routes) {
    state.bgpRoutes = routes
  },
  setBGPRoutesByIP(state, routes) {
    state.bgpRoutesByIP = routes
  },
  setIPRoutes(state, routes) {
    state.ipRoutes = routes
  },
  setIPRoutesByIP(state, routes) {
    state.ipRoutesByIP = routes
  },
  setIsLoadingIPRoutes(state, isLoading) {
    state.isLoadingIPRoutes = isLoading
  },
  setIsLoadingBGPRoutes(state, isLoading) {
    state.isLoadingBGPRoutes = isLoading
  },
  setIPRoutesError(state, error) {
    state.ipRoutesError = error
  },
  setBGPRoutesError(state, error) {
    state.bgpRoutesError = error
  },
  setBGPNeighbourRoutes(state, { type, ipAddress, routes }) {
    state.bgpNeighbourRoutesMap = {
      ...state.bgpNeighbourRoutesMap,

      // Add new value keyed by ipAddress and type
      [ipAddress]: {
        ...state.bgpNeighbourRoutesMap[ipAddress],
        [type]: routes,
      },
    }
  },
  setLoadingBGPNeighbourRoutes(state, { type, ipAddress, isLoading }) {
    state.loadingBGPNeighbourRoutes = {
      ...state.loadingBGPNeighbourRoutes,

      // Add new value keyed by ipAddress and type
      [ipAddress]: {
        ...state.loadingBGPNeighbourRoutes[ipAddress],
        [type]: isLoading,
      },
    }
  },
  setBGPNeighbourRoutesErrors(state, { type, ipAddress, error }) {
    state.bgpNeighbourRoutesErrors = {
      ...state.bgpNeighbourRoutesErrors,

      // Add new value keyed by ipAddress and type
      [ipAddress]: {
        ...state.bgpNeighbourRoutesErrors[ipAddress],
        [type]: error,
      },
    }
  },
  clearRoutes(state) {
    state.ipRoutes = []
    state.ipRoutesByIP = null
    state.bgpRoutes = []
    state.bgpRoutesByIP = null
    state.bgpNeighbourRoutesMap = {}
  },
  clearBGPNeighbourRoutes(state, { ipAddress }) {
    delete state.bgpNeighbourRoutesMap[ipAddress]
  },
  clearBGPNeighbourRoutesError(state, { ipAddress }) {
    delete state.bgpNeighbourRoutesErrors[ipAddress]
  },
  clearIPRoutes(state, data) {
    if (data.context === 'ip') {
      state.ipRoutesByIP = null
    } else {
      state.bgpRoutesByIP = null
    }
  },
  clearRouteErrors(state) {
    state.bgpRoutesError = null
    state.ipRoutesError = null
    state.bgpNeighbourRoutesErrors = {}
  },
  clearTimeoutId(state, operationId) {
    clearTimeout(state.timeoutIds[operationId])
    delete state.timeoutIds[operationId]
  },
  clearTimeoutIds(state) {
    Object.values(state.timeoutIds).forEach(clearTimeout)
    state.timeoutIds = {}
  },
  populatePayloadSchema(state) {
    // This needs to be done after the application is up and running so that we can access the translations.
    state.payloadSchema = {
      bgp: {
        displayAs: 'table',
        filterLabel: '',
        filters: [],
        tableItems: [
          { title: window.mpApp.$t('general.prefix'), id: 'prefix', data: 'prefix', sortable: true, copy: true },
          {
            title: window.mpApp.$t('services.best-route'),
            id: 'best',
            data: 'best',
            sortable: true,
            format: 'booleanTick',
            tooltip: window.mpApp.$t('connections.best-route-explanation'),
          },
          {
            title: window.mpApp.$t('general.next-hop'),
            id: 'nextHopIP',
            nested: true,
            parent: 'nextHop',
            data: 'ip',
            sortable: false,
          },
          {
            title: window.mpApp.$t('services.next-hop-vxc'),
            id: 'nextHopVXC',
            nested: true,
            parent: 'nextHop',
            data: 'vxc',
            sortable: false,
            format: 'link',
            link: { pattern: '/edit-connection/:id', value: 'name' },
            to(item) {
              return `/edit-connection/${item.vxc.id}`
            },
          },
        ],
        collapseTableItems: [
          { title: window.mpApp.$t('connections.as-path'), id: 'asPath', data: 'asPath', format: '' },
          { title: window.mpApp.$t('connections.local-preference'), id: 'localPref', data: 'localPref', format: '' },
          { title: window.mpApp.$t('connections.med'), id: 'med', data: 'med', format: '' },
          { title: window.mpApp.$t('general.origin'), id: 'origin', data: 'origin', format: '' },
          { title: window.mpApp.$t('general.communities'), id: 'communities', data: 'communities', format: 'list' },
          { title: window.mpApp.$t('general.updated'), id: 'since', data: 'since', format: 'date' },
        ],
      },

      ip: {
        displayAs: 'table',
        filterLabel: 'Protocol',
        filters: [
          { title: window.mpApp.$t('general.all-types'), value: 'all', column: 'protocol' },
          { title: window.mpApp.$t('general.static'), value: 'static', column: 'protocol' },
          { title: window.mpApp.$t('services.bgp'), value: 'bgp', column: 'protocol' },
          { title: window.mpApp.$t('general.connected'), value: 'connected', column: 'protocol' },
          { title: window.mpApp.$t('general.local'), value: 'local', column: 'protocol' },
        ],
        tableItems: [
          { title: window.mpApp.$t('general.prefix'), id: 'prefix', data: 'prefix', sortable: true, width: 20, copy: true },
          { title: window.mpApp.$t('connections.metric'), id: 'metric', data: 'metric', sortable: true },
          { title: window.mpApp.$t('connections.protocol'), id: 'protocol', data: 'protocol', sortable: true },
          { title: window.mpApp.$t('connections.distance'), id: 'distance', data: 'distance', sortable: true },
          {
            title: window.mpApp.$t('general.next-hop'),
            id: 'nextHopIP',
            nested: true,
            parent: 'nextHop',
            data: 'ip',
            sortable: false,
          },
          {
            title: window.mpApp.$t('services.next-hop-vxc'),
            id: 'nextHopVXC',
            nested: true,
            parent: 'nextHop',
            data: 'vxc',
            sortable: false,
            format: 'link',
            link: { pattern: '/edit-connection/:id', value: 'name' },
            to(item) {
              return `/edit-connection/${item.vxc.id}`
            },
          },
        ],
      },
    }
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: coreState(),
}
