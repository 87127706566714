<template>
  <div>
    <div v-for="port in serviceRequestObject"
      :key="port.productUid"
      class="service-item">
      <span v-if="port.lagPortCount">
        <template v-if="port.aggregationId">
          <span class="existing-service">{{ lagPrimaryString(port.aggregationId) }}</span><br>
        </template>
        <i class="fal fa-plus-circle"
          aria-hidden="true" />
        {{ port.productName }}: {{ port.portSpeed/1000 }} Gbps {{ $tc('ports.count-port-lag', port.lagPortCount, {count:port.lagPortCount}) }}, {{ location(port.locationId) }}
      </span>
      <span v-else
        :class="isNew(port.provisioningStatus) ? '' : 'existing-service'">
        <i v-if="isNew(port.provisioningStatus)"
          class="fal fa-plus-circle"
          aria-hidden="true" />
        <span v-else>({{ $t('general.existing') }})</span> {{ port.productName }}: {{ getPortDetails(port) }}, {{ location(port.locationId) }}
      </span>
      <div v-for="ix in port.associatedIxs"
        :key="ix.productUid"
        class="ml-2">
        <i class="fal fa-plus-circle"
          aria-hidden="true" />
        {{ ix.productName }}: {{ ix.rateLimit/1000 }} {{ $t('services.gbps-ix') }}, {{ location(ix.locationId) }}
      </div>
      <div v-for="vxc in port.associatedVxcs"
        :key="vxc.productUid"
        class="ml-2">
        <i class="fal fa-plus-circle"
          aria-hidden="true" />
        {{ vxc.productName }}: {{ vxc.rateLimit/1000 }} {{ $t('services.gbps-vxc') }}, {{ location(vxc.bEnd.locationId) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { convertProductType } from '@/helpers.js'

export default {
  name: 'ServiceRequestVidualizer',

  props: {
    serviceRequestObject: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState({
      locations: state => state.Services.locations,
    }),
  },

  methods: {
    getPortDetails(port) {
      if (port.productType === this.G_PRODUCT_TYPE_MVE) {
        // mveLabel already includes productType as MVE at start 
        return port.vendorConfig.mveLabel
      }
      return `${port.portSpeed / 1000} Gbps ${convertProductType(port.productType)}`
    },
    location(locId) {
      const foundLocation = this.locations.find(loc => {
        return loc.id === locId
      })
      if (foundLocation) {
        return foundLocation.formatted.long
      }
      return null
    },
    isNew(provisioningStatus) {
      return provisioningStatus === this.G_PROVISIONING_DESIGN || provisioningStatus === this.G_PROVISIONING_DESIGN_DEPLOY
    },
    lagPrimaryString(aggregationId) {
      let count = 0
      let lagPrimary = null
      for (const port of this.$store.state.Services.services) {
        if (port.aggregationId === aggregationId && port.provisioningStatus !== this.G_PROVISIONING_DESIGN) {
          count++
          if (port.lagPrimary) {
            lagPrimary = port
          }
        }
      }
      if (lagPrimary) {
        return `(${this.$t('ports.existing-lag')}) ${lagPrimary.productName}: ${(lagPrimary.portSpeed / 1000) * count} Gbps, ${this.location(lagPrimary.locationId)}`
      }
      return '-'
    },
  },
}
</script>

<style lang="scss" scoped>
.service-item {
  color: var(--color-text-primary);
}
.existing-service {
  color: var(--color-text-secondary);
}
</style>
