import * as globals from '@/Globals.js'

// This file is a companion to the services store, it contains some repeated logic and helper functions, so it's extracted here to keep the store clean.
// More things could live here, but this was created during a large refactor of the store

/**
 * Processes the service object (this is the form from CreateConnection -> IX) and extracts the necessary parameters for IX service.
 * @param {Object} service - The service object containing the necessary parameters.
 * @returns {Object} - The extracted IX parameters.
 */
export function processIXParams(service) {
  const ixParams = {
    vlan: service.vlan,
    asn: service.asn,
    password: service.password,
    macAddress: service.macAddress,
    name: service.productName,
    rateLimit: service.rateLimit,
    publicGraph: service.publicGraph,
    costCentre: service.costCentre,
    ixPeerMacro: service.ixPeerMacro || null,
    reverseDns: service.reverseDns,
    dealUid: fixDealUid(service.dealUid),
    shutdown: service.shutdown,
  }

  return ixParams
}

/**
 * Processes the VXC parameters for a service, the service object comes from the CreateConnection form.
 * @param {Object} service - The service object.
 * @param {string} companyUid - The company UID.
 * @param {Object} options - Additional optional parameters.
 * @param {boolean} options.updateTerm - Whether the term should be updated.
 * @param {boolean} options.aEndOwned - Whether the A-End is owned by the company.
 * @param {boolean} options.bEndOwned - Whether the B-End is owned by the company.
 * @returns {Object} - The processed VXC parameters to send off to the API.
 */
export function processVXCParams(service, companyUid, { updateTerm, aEndOwned, bEndOwned } = {}) {
  const vxcParams = {
    rateLimit: service.rateLimit,
    term: updateTerm ? service.contractTermMonths : null,
    costCentre: service.costCentre,
    dealUid: fixDealUid(service.dealUid),
  }

  // Only set the (primary) name if the company owns the A-End
  if (aEndOwned) {
    vxcParams.name = service.productName
  }
  // Only set the secondary name if the company owns the B-End (and it's not a private VXC)
  if (bEndOwned && !aEndOwned) {
    vxcParams.secondaryName = service.secondaryName
  }

  // If either the a-end or b-end is an MVE, include the vNicIndex field
  if (service.aEnd.productType === globals.G_PRODUCT_TYPE_MVE) {
    vxcParams.aVnicIndex = service.aEnd.vNicIndex
  }
  if (service.bEnd.productType === globals.G_PRODUCT_TYPE_MVE) {
    vxcParams.bVnicIndex = service.bEnd.vNicIndex
  }

  if (service.aEnd.partnerConfig) {
    vxcParams.aEndConfig = service.aEnd.partnerConfig
  }

  if (service.bEnd.partnerConfig) {
    vxcParams.bEndConfig = service.bEnd.partnerConfig
  }

  // Only allow setting the a-end vlans and vxc shutdown state if you own the a-end
  if (service.aEnd.ownerUid === companyUid) {
    vxcParams.aEndVlan = service.aEnd.vlan
    vxcParams.shutdown = service.shutdown
    if (service.aEnd.productType === globals.G_PRODUCT_TYPE_MVE) {
      vxcParams.aEndInnerVlan = service.aEnd.innerVlan
    }
  }

  // Only allow setting the b-end vlans if you own the b-end
  if (service.bEnd.ownerUid === companyUid) {
    vxcParams.bEndVlan = service.bEnd.vlan
    if (service.bEnd.productType === globals.G_PRODUCT_TYPE_MVE) {
      vxcParams.bEndInnerVlan = service.bEnd.innerVlan
    }
  }

  // An Azure connection can always set the B-End inner VLAN even if they don't own the B-End
  if (service.bEnd.connectType === 'AZURE') {
    // Pass in a -1 to indicate that they don't want to use innerVlan
    vxcParams.bEndInnerVlan = Number.parseInt(service.bEnd.innerVlan) || -1
  }

  return vxcParams
}
/**
 * Helper function that iterates over the service connections and invokes a callback function for each connection.
 * @param {Object} service - The service object containing the connections.
 * @param {Function} callback - The callback function to be invoked for each connection.
 */
export function iterateServiceConnections(service, callback) {
  for (const connectionTypeArray of [globals.G_CONNECTION_FIELD_VXC, globals.G_CONNECTION_FIELD_IX]) {
    const connections = service[connectionTypeArray]
    if (!connections) continue

    connections.forEach((connection, index) => {
      callback(connection, connectionTypeArray, index)
    })
  }
}
/**
 * Fixes null VLAN values in the service object.
 * If the VLAN value is null, it sets it to -1.
 * (untagged: null -> -1).
 * @param {Object} service - The service object to fix.
 * @returns {Object} - The fixed service object.
 */
export function fixNullVlan(service) {
  if (service.vlan && service.vlan === null) service.vlan = -1
  if (service.aEnd && service.aEnd.vlan === null) service.aEnd.vlan = -1
  if (service.bEnd && service.bEnd.vlan === null) service.bEnd.vlan = -1

  return service
}

/**
 * Fixes the deal UID by converting 'None' to null and returning the original value if it's not 'None'.
 * This is just old code that spread, so made a helper function for it
 * @param {string} dealUid - The deal UID to be fixed.
 * @returns {string|null|undefined} - The fixed deal UID.
 */
export function fixDealUid(dealUid) {
  if (dealUid === 'None') {
    return null
  } else {
    return dealUid || undefined
  }
}

/**
 * Checks if the given product type is a connection type.
 * @param {string} productType - The product type to check.
 * @returns {boolean} - Returns true if the product type is a connection type, otherwise false.
 */
export function isConnection(productType) {
  return [globals.G_PRODUCT_TYPE_VXC, globals.G_PRODUCT_TYPE_IX].includes(productType)
}

/**
 * Checks if the given product type is a service.
 * @param {string} productType - The product type to check.
 * @returns {boolean} - Returns true if the product type is a service, otherwise false.
 */
export function isService(productType) {
  return [globals.G_PRODUCT_TYPE_MEGAPORT, globals.G_PRODUCT_TYPE_MCR2, globals.G_PRODUCT_TYPE_MVE].includes(productType)
}

export function formatLocation(location, market, city, country) {
  return {
    ...location,
    city,
    country,
    market,
    formatted: {
      short: `${location.name}, ${city}`,
      long: `${location.name}, ${city}, ${country}`,
    },
  }
}

// Randomise the timeout triggers a little, so we don't have everything firing at once.
// Max at 10s additional for recently ordered services, so we don't keep customers waiting.
export function staggerPolling(nextCheck, maxDelay = 60) {
  return nextCheck + Math.floor(Math.random() * maxDelay) + 1
}

