<template>
  <section class="missing-markets-content">
    <h4>{{ $t('billing-markets.enable-markets') }}</h4>
    <p>{{ $t('billing-markets.services-no-market') }}</p>
    <p v-if="!canEditBillingMarkets"
      data-name="no-permission">
      {{ $t('billing-markets.enable-markets-admin') }}
    </p>
    <template v-if="canEditBillingMarkets">
      <template v-for="market in missingMarkets">
        <el-button :key="market.location.id"
          type="primary m-0 mb-1"
          data-name="missing-market"
          :data-market="market.market.id"
          @click="$router.push(`/company/markets/market/${market.market.id}/edit`, () => {})">
          {{ $t('sidebar.enable-market', {country: market.location.country} ) }}
        </el-button>
      </template>
    </template>
    <template v-else>
      <ul>
        <li v-for="market in missingMarkets"
          :key="market.location.id"
          data-name="missing-market"
          :data-market="market.market.id">
          {{ market.location.country }}
        </li>
      </ul>
    </template>
    <hr class="form-hr">
  </section>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'MissingMarkets',

  props: {
    missingMarkets: {
      type: Object,
      required: true,
    },
  },

  computed: {
    canEditBillingMarkets() {
      return this.$store.getters['Auth/hasAuth']('company_admin')
    },
  },
})
</script>

<style lang="scss" scoped>
.missing-markets-content {
  .el-button {
    width: 100%;
    + .el-button {
      margin-left: 0;
    }
  }
  h4 {
    text-align: center;
    background-color: var(--color-info-light-9);
    padding: 10px 0;
  }
}
</style>
