import Vue from 'vue'
import sdk from '@megaport/api-sdk'

// For data store caching of IX locations.
const coreState = {
  ixTypes: {},
  ixByLocation: {},
}

const getters = {
  getIXByLocation: state => {
    return state.ixByLocation
  },
  getIxLocationById: state => id => {
    return state.ixByLocation[id] || []
  },
}

const actions = {
  // Get the data from the cache if we have it, and otherwise load it.
  async getIxType(context, locationId) {
    // console.log(locationId)
    if (context.state.ixTypes[locationId]) {
      // console.log('Returning cached ix data')
      return context.state.ixTypes[locationId]
    }
    try {
      const locs = await sdk.instance.ixTypes(locationId)
      locs.sort((a, b) => {
        const aDesc = a.description || a.name
        const bDesc = b.description || b.name
        return (aDesc.toLowerCase() < bDesc.toLowerCase() ? -1 : 1)
      })
      context.commit('setIxTypes', {
        locationId: locationId,
        locations: locs,
      })
      return context.state.ixTypes[locationId]
    } catch (e) {
      // TODO: Improve error processing
      console.error(e)
    }
  },

  // Periodically refresh the data - it's not likely to change often, but just to make sure...
  refreshIxTypes(context) {
    Object.keys(context.state.ixTypes)
      .forEach(locationId => {
        // console.log('refreshing ' + locationId)
        sdk.instance.ixTypes(locationId)
          .then(locs => {
            locs.sort((a, b) => {
              const aDesc = a.description || a.name
              const bDesc = b.description || b.name
              return (aDesc.toLowerCase() < bDesc.toLowerCase() ? -1 : 1)
            })
            context.commit('setIxTypes', {
              locationId: locationId,
              locations: locs,
            })
          })
          .catch(err => {
            // TODO: Improve error processing
            console.error(err)
          })
      })

    setTimeout(() => {
      context.dispatch('refreshIxTypes')
    }, 60 * 60 * 4 * 1000)
  },
}

const mutations = {
  setIxTypes(state, {
    locationId,
    locations,
  }) {
    Vue.set(state.ixTypes, locationId, locations)
    state.ixByLocation[locationId] = locations
  },
  logout(state) {
    state.ixTypes = {}
    state.ixByLocation = {}
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters,
  actions,
  mutations,
}
