import integrations from '@/third-party-integrations/integrations.js'

export class OneTrust {
  #disabled = import.meta.env.VITE_DISABLE_INTEGRATION_ONETRUST === 'true'

  constructor() {
    // This needs to be able to be called from the global scope by the onetrust script,
    // and also needs to be able to access our integrations in the app, so we have to add
    // it to the window
    window.MpOneTrustConsentCallback = this.#consentChangedCallback
  }

  /**
   * Inject the OneTrust scripts into the <head> of the document
   * This is disabled in the dev environment and for whitelabel builds
   * Note that the users choice is automatically handled in GTM
   */
  init() {
    if (this.#disabled) return

    // OneTrust doesn't normally work when running the app on localhost, but if we change the domain script
    // to the test version, it will work.
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    const domainScript = `bb9601db-928e-476f-a758-86f84821fc3d${isLocalhost ? '-test' : ''}`

    let oneTrustScript = document.createElement('script')
    oneTrustScript.setAttribute('src', 'https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js')
    oneTrustScript.setAttribute('type', 'text/javascript')
    oneTrustScript.setAttribute('charset', 'UTF-8')
    oneTrustScript.setAttribute('data-domain-script', domainScript)

    let oneTrustScript2 = document.createElement('script')
    oneTrustScript2.setAttribute('type', 'text/javascript')
    oneTrustScript2.textContent = `function OptanonWrapper() {
      window.OneTrust.OnConsentChanged( () => {
        window.MpOneTrustConsentCallback()
      })
    }`

    document.head.appendChild(oneTrustScript)
    document.head.appendChild(oneTrustScript2)
  }

  #consentChangedCallback() {
    // Note that this event name is the same as what you would get if you used the Segment Onetrust plugin.
    integrations.segment.trackEvent('Segment Consent Preference Updated')
  }
}

/**
 * Utility method to determine whether the user has given consent
 * in OneTrust to use targeting cookies.
 *
 * Cookie categories are defined as follows:
 * C0001 - Strictly Necessary
 * C0002 - Performance Cookies
 * C0003 - Functional Cookies
 * C0004 - Targeting Cookies
 * C0005 - Social Media Cookies
 *
 * @returns {boolean} True if the user has authorised OneTrust
 */
export const oneTrustAuthorised = () => {
  return window.OnetrustActiveGroups?.includes('C0004')
}

export const oneTrustPermissions = () => {
  if (!window.OneTrust || !window.OnetrustActiveGroups) {
    return undefined
  }
  const allGroups = window.OneTrust.GetDomainData().Groups.map(info => info.CustomGroupId.trim())
  const activeGroups = window.OnetrustActiveGroups.trim().split(',').filter(Boolean)
  const permissions = allGroups.reduce((acc, group) => ({ ...acc, [group]: activeGroups.includes(group) }), {})
  return permissions
}
