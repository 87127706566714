import * as globals from '@/Globals.js'

const keys = Object.keys(globals).filter(key => key.startsWith('G_'))

const install = Vue => {
  for (const key of keys) {
    Vue.prototype[key] = globals[key]
  }
}

export default install
