/**
 * This code is based on https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
 *
 * Note that I tried installing the NPM package but it wouldn't build, so started from the base JS snippet.
 */
import captureSentryError from '@/utils/CaptureSentryError.js'
import { oneTrustAuthorised, oneTrustPermissions } from '@/third-party-integrations/oneTrust.js'
import store from '@/store/Store.js'
import integrations from '@/third-party-integrations/integrations.js'

// Use this key so that we can detect if the standard analytics is already installed
const ANALYTICS_KEY = 'analytics'
// Version number as per vendor's page.
const SNIPPET_VERSION = '5.2.1'

export class Segment {
  #analytics
  // This integration is disabled if either OneTrust is disabled or Segment is disabled.
  #disabled = import.meta.env.VITE_DISABLE_INTEGRATION_SEGMENT === 'true' || import.meta.env.VITE_DISABLE_INTEGRATION_ONETRUST === 'true'

  constructor() {
    if (this.#disabled) return

    window[ANALYTICS_KEY] ||= []
    this.#analytics = window[ANALYTICS_KEY]
    this.#analytics.SNIPPET_VERSION = SNIPPET_VERSION
  }

  /**
   * @param {Object} appConfiguration The environment settings loaded from environment.js
   */
  init(appConfiguration) {
    if (this.#disabled) return

    // If segment is not configured, log an error and return. Would have to be in environment.js
    if (!appConfiguration.segment?.writeKey) {
      integrations.sentry.captureMissingConfigException('Segment write key')
      this.#disabled = true
      return
    }

    // This will happen if analytics.js script is already loaded on the page. Block double loading.
    if (this.#analytics.initialize) return

    if (this.#analytics.invoked) {
      captureSentryError(new Error('Segment snippet included twice.'))
      return
    }

    this.#analytics.invoked = true
    this.#initializeMethods()
    this.#addMiddleware()
    this.#loadScript(appConfiguration.segment.writeKey)
  }

  #initializeMethods() {
    // A list of the methods in Analytics.js to stub.
    const methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'screen',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware',
      'register',
    ]

    // For each of our methods, generate a queueing stub.
    for (const functionName of methods) {
      this.#analytics[functionName] = this.#createStub(functionName)
    }
  }

  #createStub(method) {
    return function() {
      const localAnalytics = window[ANALYTICS_KEY]
      if (localAnalytics.initialized) {
        return localAnalytics[method].apply(localAnalytics, arguments)
      }

      const args = Array.from(arguments)

      if (['track', 'screen', 'alias', 'group', 'page', 'identify'].includes(method)) {
        const canonicalLink = document.querySelector("link[rel='canonical']")
        args.push({
          __t: 'bpc',
          c: canonicalLink && canonicalLink.getAttribute('href') || undefined,
          p: location.pathname,
          u: location.href,
          s: location.search,
          t: document.title,
          r: document.referrer,
        })
      }

      args.unshift(method)
      localAnalytics.push(args)
      return localAnalytics
    }
  }

  #addMiddleware() {
    this.#analytics.addSourceMiddleware(({ payload, next }) => {
      payload.obj.context.consent = {
        ...payload.obj.context.consent,
        categoryPreferences: oneTrustPermissions(),
      }
      next(payload)
    })
  }

  #loadScript(key) {
    const scriptElement = document.createElement('script')
    scriptElement.type = 'text/javascript'
    scriptElement.async = true
    scriptElement.setAttribute('data-global-segment-analytics-key', ANALYTICS_KEY)
    scriptElement.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`
    scriptElement.onerror = originalError => {
      // In the unlikely event of an error, log it as a sentry error, and set the service as disabled
      const error = new Error(`Failed to load Segment script from ${scriptElement.src}`)
      error.cause = originalError
      captureSentryError(error)
      this.#disabled = true
    }

    const firstScript = document.getElementsByTagName('script')[0]
    if (firstScript) {
      firstScript.parentNode.insertBefore(scriptElement, firstScript)
    } else {
      document.head.appendChild(scriptElement)
    }
  }

  #isLoggedInAs() {
    return store.getters['Auth/isLoggedInAs']
  }

  setUser(payload) {
    if (this.#disabled || !oneTrustAuthorised() || this.#isLoggedInAs()) return

    const { personUid, firstName, lastName, email, companyUid, companyName, accountType } = payload

    this.#analytics.identify(
      personUid,
      {
        firstName,
        lastName,
        email,
        company: companyName,
        accountType,
      }
    )

    this.#analytics.group(
      companyUid, // Company ID or UID that matches Postgres
      {
        name: companyName,
      }
    )
  }

  clearUser() {
    if (this.#disabled || !oneTrustAuthorised()) return
    // Note: This is disabled at the request of Ben, since it would reset the anonymous ID
    // which would cause the user to be treated as a new user in Segment. Since the anonymous ID
    // is used throughout the rest of our web properties, and it is unlikely that a different user
    // would be using the same browser profile, the thinking is that it's best to treat them
    // as the same user.
    // this.#analytics.reset()
  }

  /**
   * @param {object} to Vue Route object
   * @param {object} from Vue Route object
   * @param {object} properties
   */
  recordPageView(to, from, properties = {}) {
    if (this.#disabled || !oneTrustAuthorised() || this.#isLoggedInAs()) return

    const referrerPath = from.name === null ? to.path : from.path

    this.#analytics.page({
      path: to.path,
      referrer: window.location.origin + referrerPath,
      search: to.fullPath.replace(to.path, ''),
      title: to.meta.title,
      url: to.fullPath,
    }, properties)
  }

  async trackEvent(eventName, properties = {}) {
    if (this.#disabled || !oneTrustAuthorised() || this.#isLoggedInAs()) return

    // Dynamically import the router
    const { default: router } = await import('@/router')

    properties.page = {
      path: router.currentRoute.path,
      referrer: `${window.location.origin}${router.currentRoute.path}`,
      search: router.currentRoute.fullPath.replace(router.currentRoute.path, ''),
      title: router.currentRoute.meta?.title,
      url: router.currentRoute.fullPath,
    }
    this.#analytics.track(eventName, properties)
  }
}
