<template>
  <el-container :class="localeClass"
    class="height-100vh">
    <pusher v-if="!disabledFeatures.pusher" />

    <div id="intro-live-chat"
      class="position-fixed bottom-0 right-0 intro-live-chat" />

    <v-tour id="intro-tour"
      v-click-outside="stopIntro"
      name="introTour"
      :options="tourOptions"
      :steps="introSteps"
      :callbacks="introCallbacks"
      class="z-index-1000">
      <template #default="tour">
        <transition name="fade">
          <!-- eslint-disable vue/no-use-v-if-with-v-for -->
          <v-step v-for="(step, index) of tour.steps"
            v-if="tour.currentStep === index"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels">
            <template #actions>
              <el-button v-if="!tour.isLast"
                size="small"
                data-name="btn-tour-exit"
                @click.prevent="tour.stop">
                {{ $t('tour.exit') }}
              </el-button>
              <el-button v-if="!tour.isLast"
                size="small"
                data-name="btn-tour-next"
                @click.prevent="introGoNext(tour)">
                {{ $t('general.next') }} <em class="far fa-arrow-circle-right" />
              </el-button>
              <el-button v-if="tour.isLast"
                size="small"
                data-name="btn-tour-done"
                @click.prevent="tour.stop">
                {{ $t('general.done') }}
              </el-button>
            </template>
          </v-step>
          <!-- eslint-enable -->
        </transition>
      </template>
    </v-tour>

    <header-menu v-if="showHeader && !layout.integratedHeader" />

    <el-container class="main-container">
      <sidebar v-if="showSidebar" />
      <el-main class="flex-resize p-0">
        <header-menu v-if="showHeader && layout.integratedHeader" />
        <div v-if="!$appConfiguration.isProduction"
          class="non-prod sticky-top">
          {{ $t('general.non-prod') }}
        </div>
        <survey-banner v-if="$route.meta.view === 'dashboard'" />
        <partner-admin-banner v-if="$route.meta.showContextBanner && isPartnerAccount" />
        <keep-alive include="ServicesPage">
          <router-view :key="routerViewKey" />
        </keep-alive>
        <mp-footer v-if="!disabledFeatures.footer && layout.integratedFooter" />
      </el-main>
    </el-container>
    <mp-footer v-if="!disabledFeatures.footer && !layout.integratedFooter" />
  </el-container>
</template>

<script>
import '@/helpers.js'
// External tools
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
// Components
import HeaderMenuComponent from '@/components/HeaderMenu.vue'
import PusherComponent from '@/components/Pusher.vue'
import FooterComponent from '@/components/Footer.vue'
import SidebarComponent from '@/components/sidebar/Sidebar.vue'
import PartnerAdminBanner from '@/components/parent-management/PartnerAdminBanner.vue'
import SurveyBanner from '@/components/SurveyBanner.vue'

// Integrations
import integrations from '@/third-party-integrations/integrations.js'
// Globals
import { PUBLIC_COMPANY_UID, ACCOUNT_TYPE_PARTNER } from '@/Globals.js'
// CSS
import '@fortawesome/fontawesome-pro/css/all.css'

export default {
  name: 'PortalApp',

  components: {
    'header-menu': HeaderMenuComponent,
    pusher: PusherComponent,
    'mp-footer': FooterComponent,
    sidebar: SidebarComponent,
    'partner-admin-banner': PartnerAdminBanner,
    'survey-banner': SurveyBanner,
  },

  inject: ['layout', 'disabledFeatures', 'isFeatureEnabled'],

  data() {
    return {
      currentView: this.$route.meta.view,
      introStartedAtBeginning: false,
      introAllowClickOutside: false,
      portAddedUid: null,
      tourOptions: {
        enabledNavigationKeys: {
          ESCAPE: false,
        },
      },
      ACCOUNT_TYPE_PARTNER,
    }
  },

  computed: {
    ...mapGetters('Auth', ['hasFeatureFlag', 'isPartnerAccount', 'accountType', 'isManagedAccount', 'requiresMfaSetup', 'isLoggedIn', 'sessionToken', 'accessToken']),
    ...mapState({
      auth: state => state.Auth.data,
      loggedIn: state => state.Auth.loggedIn,
      company: state => state.Company.data,
      companyReady: state => state.Company.companyReady,
      eventLog: state => state.Notifications.eventLog,
      // These are in the services helper
      // services: state => state.Services.services,
      // locations: state => state.Services.locations,
      marketplace: state => state.Marketplace.marketplaceData,
      servicesReady: state => state.Services.servicesReady,
    }),
    showHeader() {
      return this.$route.meta.requiresLogin && !this.$route.meta.layout?.hideHeader
    },
    showSidebar() {
      return this.$route.meta.requiresLogin && !this.$route.meta.layout?.hideSidebar
    },
    showG2Review() {
      return this.isLoggedIn && this.servicesReady
    },
    localeClass() {
      return `locale-${this.$i18n.locale}`
    },
    showPricing() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
    introSteps() {
      const steps = []
      if (this.introStartedAtBeginning) {
        steps.push({
          preStep: () => {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.portAddedUid = null
          },
          target: '#intro-step-0',
          route: '/dashboard',
          menuItem: true,
          header: { title: this.$t('general.services') },
          content: this.$t('tour.start'),
        })
      }

      // create port
      steps.push({
        preStep: () => new Promise(resolve => {
          this.portAddedUid = null
          this.$router.push('/services')
          this.$nextTick(() => resolve())
        }),
        target: '#intro-step-1 button:first-child',
        header: { title: this.$t('general.create-thing', { thing: this.$t('productNames.port') }) },
        content: this.$t('tour.intro-port'),
        params: {
          placement: 'bottom-start',
          enableScrolling: false,
        },
      })

      // find location
      steps.push({
        preStep: () => new Promise(resolve => {
          this.$router.push('/create-megaport/port')
          this.$nextTick(() => resolve())
        }),
        target: '#intro-step-2',
        header: { title: this.$t('tour.find-location') },
        content: this.$t('tour.select-port'),
      })

      // select location
      steps.push({
        target: '#intro-select-location',
        header: { title: this.$t('tour.location-selected') },
        content: this.$t('tour.location-selected-content'),
        params: {
          enableScrolling: false,
        },
      })

      // select port config
      steps.push({
        preStep: () => new Promise(resolve => {
          document.querySelector('#nextButton').click()
          this.$nextTick(() => resolve())
        }),
        target: '#intro-config-port',
        header: { title: this.$t('tour.port-configuration') },
        content: this.$t('tour.configure-port'),
        params: {
          enableScrolling: false,
        },
      })

      // price
      if (this.showPricing) {
        steps.push({
          target: '#intro-step-3',
          header: { title: this.$t('general.price') },
          content: this.$t('tour.price'),
        })
      } else {
        steps.push({
          target: '#intro-config-port',
          header: { title: this.$t('tour.port-configured') },
          content: this.$t('tour.configured-port'),
        })
      }

      // summary
      steps.push({
        preStep: () => {
          document.querySelector('#nextButton').click()
        },
        target: '#intro-step-4',
        header: { title: this.$t('general.summary') },
        content: this.$t('tour.summary'),
      })

      // stored configuration
      steps.push({
        before: () => new Promise(resolve => {
          document.querySelector('#intro-step-4').click()
          this.$nextTick(() => resolve())
        }),
        target: '#intro-step-5',
        route: '/services',
        header: { title: this.$t('tour.port-configured') },
        content: this.$t('tour.configured-port'),
        offset: -200,
        params: {
          placement: 'top',
        },
      })

      // add service
      steps.push({
        target: '.port-line .action-block',
        header: { title: this.$t('tour.add-a-service') },
        content: this.$t('tour.add-service'),
        offset: -200,
        params: {
          placement: 'top-end',
        },
      })

      // actions
      steps.push({
        preStep: () => new Promise(resolve => {
          this.$root.$emit('showActionAside')
          this.$nextTick(() => resolve())
        }),
        target: '#intro-step-6',
        header: { title: this.$t('tour.actions-panel') },
        content: this.$t('tour.actions-panel-content'),
        offset: -200,
        params: {
          placement: 'right-start',
        },
      })

      // live chat
      !this.isChatWidgetHidden && steps.push({
        target: '#intro-live-chat',
        header: { title: this.$t('tour.live-chat') },
        content: this.$t('tour.chat'),
        params: {
          placement: 'top',
        },
      })

      if (this.disabledFeatures.knowledgeBase) {
        steps.push({
          preStep: () => {
            this.cleanupDemoPort()
          },
          route: '/dashboard',
          target: '#intro-step-0',
          header: { title: this.$t('tour.next-steps') },
          content: this.$t('tour.next-steps-no-kb'),
        })
      } else if (this.introStartedAtBeginning) {
        steps.push({
          preStep: () => {
            this.cleanupDemoPort()
          },
          route: '/dashboard',
          target: '#intro-step-0',
          header: { title: this.$t('tour.next-steps') },
          content: this.$t('tour.next-steps-kb-html'),
        })
      } else {
        steps.push({
          preStep: () => {
            this.cleanupDemoPort()
          },
          target: '#intro-step-1',
          header: { title: this.$t('tour.next-steps') },
          content: this.$t('tour.next-steps-partial-html'),
        })
      }
      return steps
    },
    introCallbacks() {
      return {
        onStart: () => {
          // Make sure there aren't any service filters screwing up the tour
          this.clearFilters()
        },
        onStop: () => {
          this.$router.push(this.introStartedAtBeginning ? '/dashboard' : '/services', () => {})
        },
        onNextStep: currentStep => {
          if ((!this.introStartedAtBeginning && currentStep === 1)
            || (this.introStartedAtBeginning && currentStep === 2)) {
            // Select the first location
            // Need to fill in the port information
            const rows = document.querySelectorAll('.item-row')
            rows[0].click()
          } else if ((!this.introStartedAtBeginning && currentStep === 3)
            || (this.introStartedAtBeginning && currentStep === 4)) {
            //  For the rest of them we will just send through the values to put in the form
            this.$root.$emit('createPortForm', {
              portSpeed: 10000,
              productName: 'test port',
            })
          }
        },
      }
    },
    introIsRunning() {
      return this.$tours['introTour'] ? this.$tours['introTour'].isRunning : false
    },
    isChatWidgetHidden() {
      return this.disabledFeatures.salesforceChat || !this.isFeatureEnabled('CUSTOMER_CHAT_ENABLED')
    },
    routerViewKey() {
      return ['CreateConnection', 'CreateMve', 'CreatePort'].includes(this.$route.name) ? this.$route.path : null
    },
  },

  watch: {
    // show/hide chat widget
    isChatWidgetHidden: {
      handler(hidden) {
        integrations.salesforceChat.setHidden(hidden || this.loggedIn !== true)
      },
    },
    auth(newVal, oldVal) {
      if ((oldVal.accessToken && !newVal.accessToken) || (oldVal.session && !newVal.session)) {
        // They must have just logged out.
        integrations.salesforceChat.setHidden(true)

        this.$router.push('/login', () => {
          // empty function is intentional
        })
      }

      if (!this.accessToken && !this.sessionToken) {
        return false
      }

      if (!this.isChatWidgetHidden) {
        integrations.salesforceChat.setup(newVal)
      }
    },
    company() {
      if (!this.accessToken && !this.sessionToken) {
        return false
      }
    },
    eventLog() {
      if (!this.company || !this.company.companyUid) return
      const max = 30
      const eventLogStore = this.eventLog.slice(this.eventLog.length - max, this.eventLog.length)
      localStorage.setItem(`_mpEventLog_${this.company.companyUid}`, JSON.stringify(eventLogStore))
    },
    'company.companyUid'(newVal, oldVal) {
      if (newVal !== null && oldVal === PUBLIC_COMPANY_UID) {
        this.$store.dispatch('Services/moveCartContents', newVal)
      }
    },
    showG2Review: {
      handler(tf) {
        if (tf) {
          integrations.g2Review.activateG2()
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.$root.$on('introStartedAtBeginning', tf => {
      this.introStartedAtBeginning = tf
    })
  },

  beforeDestroy() {
    this.$root.$off('introStartedAtBeginning')
    this.$root.$off('portAdded')
  },


  created() {
    this.$store.dispatch('onInit')

    this.$root.$on('portAdded', portUid => {
      this.portAddedUid = portUid
    })
  },

  methods: {
    ...mapMutations('ServiceFilters', ['clearFilters']),
    ...mapActions('Services', ['removeServiceOrConnection']),

    introGoNext(tour) {
      // This can only be called if we are not on the last step, so
      // don't worry about index.
      const currentStep = tour.currentStep
      const nextStep = currentStep + 1
      this.introAllowClickOutside = true // So we don't accidentally exit the demo

      // If there is anything that needs to be run first, do that
      if (tour.steps[nextStep].preStep) {
        tour.steps[nextStep].preStep()
      }
      this.$nextTick(() => {
        if (tour.steps[nextStep].route) {
          this.$router.push(tour.steps[nextStep].route, () => {
            // empty function is intentional
          })
          this.$nextTick(() => {
            if (tour.steps[nextStep].menuItem && !document.querySelector(tour.steps[nextStep].target) && document.getElementById('hamburgerTriggerButton')) {
              document.getElementById('hamburgerTriggerButton').click()
              this.$nextTick(() => {
                tour.nextStep()
                this.introAllowClickOutside = false
              })
            } else {
              tour.nextStep()
              this.introAllowClickOutside = false
            }
          })
        } else {
          this.$nextTick(() => {
            if (tour.steps[nextStep].menuItem && !document.querySelector(tour.steps[nextStep].target) && document.getElementById('hamburgerTriggerButton')) {
              document.getElementById('hamburgerTriggerButton').click()
              this.$nextTick(() => {
                tour.nextStep()
                this.introAllowClickOutside = false
              })
            } else {
              tour.nextStep()
              this.introAllowClickOutside = false
            }
          })
        }
      })
    },
    introGoPrev(tour) {
      const previousStep = tour.currentStep - 1
      this.introAllowClickOutside = true // So we don't accidentally exit the demo

      const nextSteps = () => {
        if (
          tour.steps[previousStep].menuItem &&
          !document.querySelector(tour.steps[previousStep].target) &&
          document.getElementById('hamburgerTriggerButton')
        ) {
          document.getElementById('hamburgerTriggerButton').click()
          this.$nextTick(() => {
            tour.previousStep()
            this.introAllowClickOutside = false
          })
        } else {
          tour.previousStep()
          this.introAllowClickOutside = false
        }
      }
      if (tour.steps[previousStep].route) {
        this.$router.push(tour.steps[previousStep].route, () => {
          // empty function is intentional
        })
        this.$nextTick(nextSteps)
      } else {
        this.$nextTick(nextSteps)
      }
    },
    stopIntro() {
      if (this.introAllowClickOutside) {
        return
      }
      if (!this.introIsRunning) {
        return
      }
      this.cleanupDemoPort()
      this.$tours['introTour'].stop()
    },
    cleanupDemoPort() {
      if (this.portAddedUid) {
        this.removeServiceOrConnection({ productUid: this.portAddedUid, cartCleanup: true })
        this.portAddedUid = null
      }
    },
  },
}
</script>

<style lang="scss">
// Service status colours

// Customer has kicked-off the cancellation process - the service is still active in provisioning and billing terms until it is terminated.
.service--cancelled,
.service--cancelled_parent {
  color: var(--color-info);
  &.hover-lighter {
    &:hover {
      color: var(--color-info-light-3);
    }
  }
}
.service--cancelled_bg,
.service--cancelled_parent_bg {
  background-color: var(--color-info);
}

// Portal or API user has saved a service design but has not committed to ordering said design.
.service--design {
  color: var(--color-primary);
  &.hover-lighter {
    &:hover {
      color: var(--color-primary-light-3);
    }
  }
}
.service--design_bg {
  background-color: var(--color-primary);
}

//Service provisioning has commenced - waiting on Megaport
.service--deployable {
  color: var(--color-warning);
  &.hover-lighter {
    &:hover {
      color: var(--color-warning-light-3);
    }
  }
}
.service--deployable_bg {
  background-color: var(--color-warning);
}

// All Megaport provisioning activities have been completed, in the case of
// a Port: waiting on customer to plug in
.service--configured {
  color: var(--color-warning);
  &.hover-lighter {
    &:hover {
      color: var(--color-warning-light-3);
    }
  }
}
.service--configured_bg {
  background-color: var(--color-warning);
}

// Megaport: Customer has plugged in to the Port. Services: Provisioning has completed
.service--live {
  color: var(--color-success);
  &.hover-lighter {
    &:hover {
      color: var(--color-success-light-3);
    }
  }
}
.service--live_bg {
  background-color: var(--color-success);
}

.service--failed {
  color: var(--color-danger);
  &.hover-lighter {
    &:hover {
      color: var(--color-danger-light-3);
    }
  }
}
.service--failed_bg {
  background-color: var(--color-danger);
}

// In the case of Ports, the customer is physically disconnected from Megaport assets.
.service--decommissioned {
  color: var(--color-info);
  &.hover-lighter {
    &:hover {
      color: var(--color-info-light-3);
    }
  }
}
.service--decommissioned_bg {
  background-color: var(--color-info);
}

// The service is irreversibly terminated. We do not show these in the Megaport since it would be useless clutter.
.service--terminated {
  color: var(--color-info);
  &.hover-lighter {
    &:hover {
      color: var(--color-info-light-3);
    }
  }
}
.service--terminated_bg {
  background-color: var(--color-info);
}

// Portal or API user has ordered a service design and the provisioning work is ready to be executed.
// This happens so quickly that by the time we have seen it, it has changed to another status anyway,
// so there is probably no need to handle in the portal, but we will create a colour just in case.
// Note that this is now used for situations where you have a configured service which has an action
// that needs to be performed before it can be deployed (see conflict)
.service--new {
  color: var(--color-warning);
  &.hover-lighter {
    &:hover {
      color: var(--color-warning-light-3);
    }
  }
}
.service--new_bg {
  background-color: var(--color-warning);
}

// Internal status set when the information is being fetched. Replaced with status from the API.
.service--loading {
  color: var(--color-info);
  &.hover-lighter {
    &:hover {
      color: var(--color-info-light-3);
    }
  }
}
.service--loading_bg {
  background-color: var(--color-info);
}

// Internal status set when the design is being submitted to the API for deployment.
.service--design_deploy {
  color: var(--color-warning);
  &.hover-lighter {
    &:hover {
      color: var(--color-warning-light-3);
    }
  }
}
.service--design_deploy_bg {
  background-color: var(--color-warning);
}

.halfway {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.el-popover.tooltip-lookalike {
  background-color: var(--color-text-primary);
  color: white;
  padding: 10px;
  font-size: 1.3rem;
  min-width: 50px;
  border-radius: 6px;
  text-align: left;
  white-space: pre-line;
  line-height: 1.375em;
  word-break: normal;
}

.el-popper[x-placement^="bottom"].tooltip-lookalike div {
  border-bottom-color: var(--color-text-primary);
  &:after {
    border-bottom-color: var(--color-text-primary);
  }
}

.el-popper[x-placement^="top"].tooltip-lookalike div {
  border-top-color: var(--color-text-primary);
  &:after {
    border-top-color: var(--color-text-primary);
  }
}

.el-popper[x-placement^="left"].tooltip-lookalike div {
  border-left-color: var(--color-text-primary);
  &:after {
    border-left-color: var(--color-text-primary);
  }
}
.el-popper[x-placement^="right"].tooltip-lookalike div {
  border-right-color: var(--color-text-primary);
  &:after {
    border-right-color: var(--color-text-primary);
  }
}

.auth-hide {
  display: none;
}

#intro-tour .v-step {
  background-color: var(--color-primary-light-9);
  border: 1px solid var(--color-primary-light-2);
  color: var(--color-text-regular);
  border-radius: var(--border-radius-base);
  filter: none;
  box-shadow: 0 0 5px 0px var(--color-primary-light-5);

  .v-step__header {
    color: var(--color-text-primary);
    background-color: var(--color-primary-light-9);
    font-size: 1.6rem;
    font-weight: 500;
    border-bottom: 1px solid var(--color-primary-light-5);
  }
  .v-step__arrow {
    border-color: var(--color-primary-light-2);
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
  }
  &[x-placement="right"] .v-step__arrow {
    border-color: var(--color-primary-light-2);
    border-bottom-color: transparent;
    border-top-color: transparent;
  }
  &[x-placement="top"] .v-step__arrow {
    border-color: var(--color-primary-light-2);
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
}

.flex-resize {
  transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);
}
</style>

<style lang="scss" scoped>
// This allows us to put popovers on individual radio buttons and have them
// display on hover anywhere on the button.
.inverse-padding {
  .el-radio-button__inner {
    padding: 0;
  }
  .el-popover__reference {
    padding: 12px 20px;
  }
}

.env-fail {
  margin: auto;
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 1rem 2rem;
  text-align: center;
}

.height-100vh {
  height: 100vh;
}

.intro-live-chat {
  width: 90px;
  height: 90px;
  pointer-events: none;
}

.z-index-1000 {
  z-index: 1000;
}

.non-prod {
  background-color: var(--color-warning-light-6);
  padding: 1.5rem;
}
</style>

<style src="vue-tour/dist/vue-tour.css"></style>
