import Vue from 'vue'
import VueTour from 'vue-tour'
import * as vClickOutside from 'v-click-outside-x'
import App from './App.vue'
import router from './router'
import store from './store/Store.js'
import MegaportSDK, { MegaportError } from '@megaport/api-sdk'
import GlobalsPlugin from '@/plugins/globals.js'
import providers from '@/providers'
import { getI18n } from '@/i18n/SetupI18n.js'
import integrations, { setupIntegrations } from '@/third-party-integrations/integrations.js'
import { captureZodParseError } from '@/utils/CaptureSentryError'
import 'vue-cal/dist/vuecal.css'

import '@/plugins/element.js'
import '@/plugins/mega-ui.js'
import '@/directives/permissions'
import '@/utils/demoData'
import '@/filters'
import { X_APP } from './Globals'

Vue.use(vClickOutside)
Vue.use(VueTour)
Vue.use(GlobalsPlugin)

Vue.prototype.$appConfiguration = {}

// This is used by DLR to enable them to directly log in as a particular user
// by passing in a token in the URL (See ENG-14340).
const setTokenByURI = location.pathname.match(/token\/([a-z0-9-]+)/i)
if (setTokenByURI) {
  localStorage.setItem('_token', setTokenByURI[1])
}

// Dynamically import environment file
// File can be local root (/public folder) or external URL (e.g. http://someurl/environment.js)
import(/* @vite-ignore */ `${import.meta.env.BASE_URL}environment.js`).then(async environment => {
  // Sanity check that environment.js actually contains something
  if (!environment.default) {
    console.error('Unable to mount application: Missing environment settings!')
    return
  }

  // Environment
  const appConfiguration = {
    ...environment.default,
    isProduction: environment.default.environmentName === 'production',
  }
  window.environmentName = appConfiguration.environmentName

  // Inject settings into Vue components
  Vue.prototype.$appConfiguration = appConfiguration
  // Setup API-SDK
  const sdk = new MegaportSDK(appConfiguration.apiUrl, import.meta.env.VITE_SEND_CREDENTIALS === 'true', X_APP)

  // Setup Third Party Integrations
  setupIntegrations(appConfiguration)

  // Wait for i18n to be ready before starting the app
  const i18n = await getI18n()

  // Initialise Vue
  const settings = {
    router,
    store,
    render: h => h(App),
    i18n,
    provide: providers,
  }
  window.mpApp = new Vue(settings).$mount('#mp')

  // General API error handler.
  // NOTE: Return true (error has been handled) if you want to indicate to other error handlers to skip processing the error.
  sdk.apiErrorCallback = megaportError => {
    // This matches versioned logout endpoints - /v1/logout, /v2/logout, /v20/logout, etc
    const versionedLogoutEndpointRegex = /^\/v[0-9]+\/logout$/

    // Handle failed auth (401), but only when it has not failed calling the logout endpoint.
    if (megaportError.status === 401 && !versionedLogoutEndpointRegex.test(megaportError.requestedUrl)) {
      const path = window.mpApp.$route.path
      const pathWhiteList = ['/signup', '/user-profile', '/mfa', '/post-login', '/aws-marketplace']

      // Paths here will handle the error in a different manner, so ignore.
      if (pathWhiteList.includes(path)) return false

      // This handles some dynamic paths, so not listed above.
      // /login /login/username/:username /loginas/:targetUsername? /login/help
      if (path.startsWith('/login') || path.startsWith('/onboard')) return false

      if (import.meta.env.VITE_BYPASS_AUTHENTICATION !== 'true') {
        // Logout the user (which redirects to /login) and display error.
        // Previously this was just redirecting, but it wasn't clearing localStorage
        // or triggering any of the other cleanups done by logout, which caused issues.
        // So we now dispatch logout and trigger all of its side effects on 401s.
        window.mpApp.$store.dispatch('Auth/logout')
          .then(() => {
            // Only side effect is handled in the finally block so that the user is notified in all cases.
          })
          .catch(error => {
            // If we get a MegaportError here, it means that the logout call to the API failed.
            // In this case, we are most likely dealing with an unauthenticated user, so calling
            // logout again would be pointless and would lead to an infinite (or at least a lengthy) loop.
            // Instead, we just ensure we clear all user-related state and redirect to the login page.
            if (error instanceof MegaportError) {
              // Clear integration user information
              integrations.salesforceChat.logout()
              integrations.postHog.clearUser()
              integrations.segment.clearUser()
              // Trigger the onLogout side-effects
              window.mpApp.$store.dispatch('onLogout')
              // Clear API-SDK session
              sdk.clearSession()
            }
          })
          .finally(() => {
            window.mpApp.$notify.error({
              title: window.mpApp.$t('authentication.please-login'),
              message: window.mpApp.$t('authentication.failed-login'),
              duration: 10000,
            })
          })

        return true
      }
    }

    // Any other errors are unhandled
    return false
  }

  // Callback for logging API response parsing errors in API-SDK
  sdk.parsingErrorCallback = captureZodParseError
})
