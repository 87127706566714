import { G_PRODUCT_TYPE_MCR2, G_PRODUCT_TYPE_MEGAPORT } from '@/Globals'
import integrations from '@/third-party-integrations/integrations'

/**
 * Helper function for sending a custom event to all analytic integrations
 * @param {string} event The code of the event
 * @param {Object} [payload] Extra key/value data to be associated with the event
 */
export const captureEvent = (event, payload) => {
  integrations.googleTagManager.push('event', event, payload)
  integrations.postHog.captureEvent(event, payload)
}

/**
 * Helper function for converting a raw product type to a type that matches event naming convention
 * @param {string} productType The raw product type returned by the API
 * @returns {string} A product type suitable for inclusion in event names
 */
export const productTypeToEvent = productType => {
  switch (productType) {
    case G_PRODUCT_TYPE_MEGAPORT: return 'port'
    case G_PRODUCT_TYPE_MCR2: return 'mcr'
    default: return productType.toLowerCase()
  }
}
