<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :title="$t('sidebar.save-configured-services')"
    :modal-append-to-body="false">
    <div v-loading="savingConfiguration"
      :element-loading-text="$t('general.saving')"
      data-name="save-dialog-content">
      <p class="mb-2 mt-0">
        {{ $t('sidebar.saved-configurations-alert') }}
      </p>

      <el-form ref="saveConfigurationForm"
        :model="saveConfigurationForm"
        :rules="saveConfigurationRules"
        label-width="90px"
        @submit.native.prevent>
        <!-- Saved Configuration Name -->
        <el-form-item prop="saveName"
          :label="$t('general.save-as')">
          <el-input v-model="saveConfigurationForm.saveName"
            name="saveName"
            data-testid="save-configuration-name"
            autofocus />
        </el-form-item>

        <!-- Clear Current Configured Services -->
        <el-checkbox v-model="saveConfigurationForm.clear"
          name="clear"
          data-testid="clear-existing-services"
          class="ml-9">
          {{ $t('sidebar.clear-configured-services') }}
        </el-checkbox>

        <!-- Existing Configuration Name Warning -->
        <el-collapse-transition>
          <p v-if="existingSavedConfiguration"
            class="existing-save-warning ml-9">
            {{ $t('sidebar.existing-configuration-warning') }}
          </p>
        </el-collapse-transition>
      </el-form>
    </div>

    <template #footer>
      <el-button @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
      <el-button type="primary"
        data-name="saveConfiguration"
        @click="saveConfiguration">
        {{ $t('general.save') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SaveConfigurationModal',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    savedConfigurations: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['update:visible'],

  data() {
    return {
      showSaveConfiguration: false,
      savingConfiguration: false,
      saveConfigurationForm: {
        saveName: null,
        clear: false,
      },
      saveConfigurationRules: {
        saveName: { required: true, message: this.$t('sidebar.save-configured-name'), trigger: 'blur' },
      },
    }
  },

  computed: {
    /**
     * Check if the configuration name already exists
     */
    existingSavedConfiguration() {
      return this.savedConfigurations.find(config => {
        return config.title === this.saveConfigurationForm.saveName
      })
    },
  },

  methods: {
    ...mapActions('Services', ['persistCartToServer', 'clearCart']),

    /**
     * Set modal visibility and reset modal state
     * @param {boolean} newValue Modal visibility
     */
    setVisible(newValue) {
      this.saveConfigurationForm = {
        saveName: null,
        clear: false,
      }
      this.showSaveConfiguration = true
      this.$nextTick(() => {
        if (this.$refs.saveConfigurationForm) {
          this.$refs.saveConfigurationForm.clearValidate()
        }
      })
      this.$emit('update:visible', newValue)
    },
    /**
     * Hide modal when closing
     * @param {Function} done
     */
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    /**
     * Save the order configuration to the API
     */
    saveConfiguration() {
      this.$refs.saveConfigurationForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)

          return
        }
        this.savingConfiguration = true
        let serviceOrderUid = null
        if (this.existingSavedConfiguration) {
          serviceOrderUid = this.existingSavedConfiguration.serviceOrderUid
        }
        this.persistCartToServer({ title: this.saveConfigurationForm.saveName, serviceOrderUid: serviceOrderUid })
          .then(() => {
            if (this.saveConfigurationForm.clear) {
              this.clearCart()
            }
            this.savingConfiguration = false
            this.setVisible(false)
          })
          .catch(e => {
            console.error(e)
            this.savingConfiguration = false
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.existing-save-warning {
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 1rem 2rem;
  margin: 1rem 0;
}

.ml-9 {
  margin-left: 9rem;
}
</style>
