<template>
  <el-popover ref="popover"
    v-bind="$attrs"
    v-model="internalValue"
    v-on="$listeners"
    @show="updateDisplaying(true)"
    @hide="updateDisplaying(false)">
    <slot v-if="displaying">
      {{ content }}
    </slot>
    <template slot="reference">
      <slot name="reference">
        {{ reference }}
      </slot>
    </template>
  </el-popover>
</template>

<script>
// The intent of this component is to provide a wrapper around the popover that
// causes it to only render the content if the popover is displayed. This is done
// purely for efficiency reasons.
export default {
  name: 'LazyPopover',

  props: {
    content: {
      type: String,
      required: false,
      default: '',
    },
    reference: {
      type: Object,
      required: false,
      default: () => {},
    },
    // Map the value which is set by v-model through to the popover, but
    // since you are not supposed to mutate a prop directly, we map it through
    // to internalValue and emit the value out as an input when it changes.
    value: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      displaying: false,
      internalValue: this.value,
    }
  },

  watch: {
    value() {
      this.internalValue = this.value
    },
    internalValue() {
      this.$emit('input', this.internalValue)
    },
  },

  methods: {
    updateDisplaying(tf) {
      this.displaying = tf
      tf ? this.$emit('show') : this.$emit('hide')
    },
  },
}
</script>
