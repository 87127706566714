<template>
  <el-alert v-if="showSurveyBanner"
    class="survey-banner sticky-top"
    :class="{ 'survey-banner-partner': isPartnerAccount }"
    @close="closeSurveyBanner">
    <a class="survey-link"
      target="_blank"
      href="https://docs.google.com/forms/d/1c3gcIJNIW9NFvYlHMuk2mpxB4Yq5ZHPjRFXsshR1-a0/viewform">{{ $t('survey-banner.customer-survey-link') }}</a>
  </el-alert>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SurveyBanner',
  data() {
    return {
      now: new Date(),
      endDate: new Date(2023, 6, 1),
    }
  },
  computed: {
    ...mapGetters('Auth', ['isPartnerAccount']),
    surveyBannerEnabled() {
      return (import.meta.env.VITE_DISABLE_SURVEY_BANNER === 'false')
    },

    showSurveyBanner() {
      return this.surveyBannerEnabled && this.now < this.endDate && !localStorage.getItem('_hideSurveyBanner')
    },
  },
  methods: {
    closeSurveyBanner() {
      localStorage.setItem('_hideSurveyBanner', JSON.stringify(true))
    },
  },
}
</script>

<style lang="scss" scoped>
.el-alert--info.survey-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0px 70px;
  background-color: var(--color-vantage-blue);
  height: 50px;
  color: white;
  z-index: 4;
  box-shadow: 0 0px 9px 2px rgb(0 0 0 / 55%);
  border-radius: 0;
  background-image: url('../assets/images/survey-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  background-position: center center;
}

.survey-link {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}

.survey-banner-partner {
  border-bottom: 1px solid white;
}
</style>

