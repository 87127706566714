<template>
  <section>
    <el-dialog :visible.sync="showLoadConfiguration"
      :title="$t('services.replace-configured')"
      :modal-append-to-body="false">
      <div role="presentation"
        data-name="load-dialog">
        {{ $t('services.replace-confirmation') }}
      </div>
      <h3 class="text-align-center my-2 mx-0">
        {{ $t('services.configuration-details') }}
      </h3>
      <sr-visualizer :service-request-object="selectedServiceRequest" />

      <template #footer>
        <el-button @click="showLoadConfiguration = false">
          {{ $t('general.cancel') }}
        </el-button>
        <el-button type="warning"
          data-name="replace-services-button"
          @click="loadSavedConfiguration">
          {{ $t('services.replace-configured') }}
        </el-button>
      </template>
    </el-dialog>

    <el-dialog :visible.sync="showDeleteConfiguration"
      :title="$t('services.delete-configuration')"
      :modal-append-to-body="false">
      <h3 class="text-align-center mb-2"
        data-name="delete-dialog">
        {{ $t('services.configuration-details') }}
      </h3>
      <sr-visualizer :service-request-object="selectedServiceRequest" />
      <template #footer>
        <el-button @click="showDeleteConfiguration = false">
          {{ $t('general.cancel') }}
        </el-button>
        <el-button type="warning"
          data-name="delete-saved-config-button"
          @click="deleteSavedConfiguration">
          {{ $t('general.delete') }}
        </el-button>
      </template>
    </el-dialog>

    <section class="saved-configs-content">
      <h4 class="mb-2">
        {{ $t('sidebar.saved-configurations') }}
      </h4>
      <div v-for="configuration in savedConfigurations"
        :key="configuration.serviceOrderUid"
        class="mb-1 ml-0-5">
        <el-popover placement="right"
          trigger="hover"
          :open-delay="500">
          <sr-visualizer :service-request-object="configuration.serviceRequestObject" />
          <el-radio slot="reference"
            v-model="selectedSavedConfiguration"
            :data-name="`design_${configuration.title}`"
            class="saved-configs"
            :label="configuration.serviceOrderUid">
            {{ configuration.title }}
          </el-radio>
        </el-popover>
      </div>
      <el-row class="mt-2"
        :gutter="8">
        <el-col :span="12">
          <el-button :disabled="!selectedSavedConfiguration"
            data-name="delete-button"
            @click="showDeleteConfiguration = true">
            {{ $t('general.delete') }}
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="primary"
            :disabled="!selectedSavedConfiguration"
            data-name="load-button"
            @click="tryToLoadSavedConfiguration">
            {{ $t('general.load') }}
          </el-button>
        </el-col>
      </el-row>
      <hr class="form-hr">
    </section>
  </section>
</template>

<script>
import Vue from 'vue'
import ServiceRequestVisualizerComponent from '@/components/ui-components/ServiceRequestVisualizer.vue'

export default Vue.extend({
  name: 'SavedConfigurations',

  components: {
    'sr-visualizer': ServiceRequestVisualizerComponent,
  },

  props: {
    savedConfigurations: {
      type: Array,
      required: true,
    },
    configuredItems: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedSavedConfiguration: null,
      showDeleteConfiguration: false,
      showLoadConfiguration: false,
    }
  },

  computed: {
    selectedServiceRequest() {
      if (!this.selectedSavedConfiguration) {
        return []
      }
      const selectedConfig = this.savedConfigurations?.find(config => {
        return config.serviceOrderUid === this.selectedSavedConfiguration
      })
      if (selectedConfig) {
        return selectedConfig.serviceRequestObject
      }
      return []
    },
  },

  methods: {
    deleteSavedConfiguration() {
      this.$store.dispatch('Services/deleteCartFromServer', { serviceOrder: this.selectedSavedConfiguration })
      this.selectedSavedConfiguration = null
      this.showDeleteConfiguration = false
    },
    tryToLoadSavedConfiguration() {
      if (this.configuredItems.length) {
        this.showLoadConfiguration = true
      } else {
        this.loadSavedConfiguration()
      }
    },
    loadSavedConfiguration() {
      this.$store.dispatch('Services/clearCart')
      this.$store.dispatch('Services/loadCart', this.selectedSavedConfiguration)
      this.showLoadConfiguration = false
    },
  },
})
</script>

<style lang="scss" scoped>
.saved-configs-content {
  .el-button {
    width: 100%;
  }
  h4 {
    text-align: center;
    background-color: var(--color-info-light-9);
    padding: 10px 0;
  }
}
</style>

<style lang="scss">
.saved-configs .el-radio__input {
  vertical-align: top;
}
.saved-configs .el-radio__label {
  white-space: normal;
  display: inline-block;
  word-break: break-all;
  padding-right: 10px;
}
.saved-configs {
  padding-right: 10px;
}
</style>
