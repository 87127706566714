import Vue from 'vue'

/**
 * Returns a link to a survey with the specified code, replacing placeholders with the provided parameters.
 * @param {string} surveyCode The code of the survey to link to. Matches a key in the surveyUrls object in the
 * environment settings.
 * @param {Record<string, string>} [params] Parameters to replace in the survey URL. The parameter placeholders will be
 * in the format `{key}`, and will be replaced with the corresponding value from this object.
 * @returns {string} The survey link with the placeholders replaced.
 */
export const getSurveyLink = (surveyCode, params = {}) => {
  let link = Vue.prototype.$appConfiguration.surveyUrls?.[surveyCode] ?? ''
  for (const [key, value] of Object.entries(params)) {
    link = link.replaceAll(`{${key}}`, encodeURIComponent(value))
  }
  return link
}
