<template>
  <span>
    <span class="sr-only">
      {{ $t('general.open-new-tab') }}
    </span>
    <i class="fas fa-arrow-up-right-from-square external-link-icon"
      aria-hidden="true"
      data-testid="external-link-icon" />
  </span>
</template>

<script>
export default {
  name: 'ExternalLinkIndicator',
}
</script>

<style lang="scss" scoped>
  .external-link-icon {
    vertical-align: super;
    font-size: 0.8rem;
    color: var(--color-primary);
  }
</style>
