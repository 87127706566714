import store from '@/store/Store.js'

export class GoogleLogin {
  #disabled = import.meta.env.VITE_DISABLE_INTEGRATION_GOOGLE_LOGIN === 'true'

  /**
   * Use this to install the script at startup.
   */
  init() {
    if (this.#disabled) return

    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    script.onload = () => {
      store.commit('Auth/setGoogleSignInReady', true)
    }

    document.body.appendChild(script)
  }
}
