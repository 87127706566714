import sdk from '@megaport/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { filterEventsByServices, ianaTimeZones } from '@/helpers'

const SORT_BY_OPTIONS = Object.freeze(['state', 'startTime'])
const SORT_DIRECTION_OPTIONS = Object.freeze(['ascending', 'descending'])
const VIEW_TAB_OPTIONS = Object.freeze(['month', 'week', 'day'])
const STATE_OPTIONS = Object.freeze({
  maintenance: ['Scheduled', 'Running', 'Completed', 'Cancelled'],
  outages: ['Ongoing', 'Resolved'],
})

const coreState = () => ({
  fetchRetriesLeft: 3,
  fetchingEvents: false,
  maintenanceEvents: [],
  outageEvents: [],
  fetchEnsEventsTimeout: null,
  tab: 'maintenance',
  filters: {
    maintenance: {
      sortBy: 'startTime',
      sortDirection: 'descending',
      searchText: '',
      state: ['Scheduled', 'Running'],
      view: 'calendar',
      viewTab: 'week',
      timeZone: 'UTC',
    },
    outages: {
      sortBy: 'startTime',
      sortDirection: 'descending',
      searchText: '',
      state: ['Ongoing'],
      timeZone: 'UTC',
    },
  },
})

const coreGetters = {
  selectedTimeZone: state => {
    const { filters, tab } = state
    return filters[tab].timeZone.replace(/_/g, ' ')
  },
  isMaintenanceTab: state => {
    return state.tab === 'maintenance'
  },
  isCalendarView: (state, getter) => {
    return getter.isMaintenanceTab && state.filters.maintenance.view === 'calendar'
  },

  allMaintenanceEvents: (state, getters) => {
    return filterEventsByServices(state.maintenanceEvents, getters.allServicesShortUidList)
  },
  allOutageEvents: (state, getters) => {
    return filterEventsByServices(state.outageEvents, getters.allServicesShortUidList)
  },

  uidsWithOngoingMaintenance: state => {
    if (!state.maintenanceEvents) return []

    const allServiceUids = state.maintenanceEvents.flatMap(event => (event.state === 'Running' ? event.services : []))
    return [...new Set(allServiceUids)]
  },
  uidsWithOngoingOutages: state => {
    if (!state.outageEvents) return []

    const allServiceUids = state.outageEvents.flatMap(event => (event.state === 'Ongoing' ? event.services : []))
    return [...new Set(allServiceUids)]
  },

  /**
   * A collection of all port-like services associated to the user and all their connections keyed by short productUid.
   * This collection is being created for the quick access of all services data through Short UID without the need of iteration.
   */
  allServicesShortUidDictionary: (_state, _getters, _rootState, rootGetters) => {
    if (!rootGetters['Services/allMyServices']) return []
    return rootGetters['Services/allMyServices'].reduce((finalObject, service) => {
      const shortUid = service._subUid ?? service.productUid.split('-')[0]
      finalObject[shortUid] = service
      return finalObject
    }, {})
  },

  /**
   * Array list of Short UIDs of allServicesShortUidDictionary.
   */
  allServicesShortUidList: (_state, getters) => {
    return Object.keys(getters.allServicesShortUidDictionary)
  },
}

const actions = {
  /**
   * Fetch Maintenance & Outage events data for the provided states
   * @param {*} context (store context)
   */
  async fetchEnsEvents(context) {
    context.commit('setFetchingEvents', true)
    try {
      // Initiate both API calls concurrently
      const [maintenanceEvents, outageEvents] = await Promise.all([
        sdk.instance.servicesStatus().maintenance(STATE_OPTIONS.maintenance),
        sdk.instance.servicesStatus().outage(STATE_OPTIONS.outages),
      ])
      context.commit('setEnsEvents', { maintenanceEvents, outageEvents })
      context.commit('setFetchRetriesLeft', 3)
      context.dispatch('periodicallyLoadEnsEvents')
    } catch (error) {
      captureSentryError(error)
      if (context.state.fetchRetriesLeft > 0) {
        context.dispatch('fetchEnsEvents')
        context.commit('setFetchRetriesLeft', context.state.fetchRetriesLeft - 1)
      } else {
        window.mpApp.$notify({
          title: window.mpApp.$t('service-status.refresh-error'),
          message: error.message || error.data.message,
          type: 'error',
          duration: 5000,
        })
      }
      // if (!error.handled) throw error
    } finally {
      context.commit('setFetchingEvents', false)
    }
  },

  periodicallyLoadEnsEvents(context) {
    // Clear existing timeout, to handle a case where user manually refresh events & it trigger multiple timeouts
    context.commit('clearTimeout')
    // Periodically refresh the list of services in every 5 mins in case someone else has added or updated some events.
    const timeoutId = setTimeout(() => {
      context.dispatch('fetchEnsEvents')
    }, 5 * 60 * 1000)
    context.commit('setFetchEnsEventsTimeout', timeoutId)
  },

  logout(context) {
    context.commit('clearTimeout')
    context.commit('clearEvents')
  },
}

const mutations = {
  setFetchRetriesLeft (state, number) {
    state.fetchRetriesLeft = number
  },

  setFetchingEvents (state, tf) {
    state.fetchingEvents = tf
  },

  setEnsEvents(state, payload) {
    Object.assign(state, payload)
  },

  setFetchEnsEventsTimeout(state, newValue) {
    state.fetchEnsEventsTimeout = newValue
  },

  updateFilters(state, { tab, newValues }) {
    newValues.forEach(([key, value]) => {
      let updatedValue = value

      switch (key) {
        case 'sortBy':
          updatedValue = SORT_BY_OPTIONS.includes(value) ? value : 'startTime'
          break
        case 'sortDirection':
          updatedValue = SORT_DIRECTION_OPTIONS.includes(value) ? value : 'descending'
          break
        case 'state': {
          const values = Array.isArray(value) ? value : [value]
          updatedValue = [...new Set(values)].filter(st => STATE_OPTIONS[tab].includes(st))
          break
        }
        case 'timeZone':
          updatedValue = ianaTimeZones.includes(value) ? value : 'UTC'
          break
        case 'view':
          updatedValue = tab === 'maintenance' ? value : ''
          break
        case 'viewTab':
          updatedValue = (tab === 'maintenance' && VIEW_TAB_OPTIONS.includes(value)) ? value : 'week'
          break
      }

      state.filters[tab][key] = updatedValue
    })
  },

  updateTab (state, newTab) {
    state.tab = newTab
  },

  clearTimeout(state) {
    if (state.fetchEnsEventsTimeout) {
      clearTimeout(state.fetchEnsEventsTimeout)
      state.fetchEnsEventsTimeout = null
    }
  },

  clearEvents(state) {
    state.maintenanceEvents = []
    state.outageEvents = []
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters: coreGetters,
  actions,
  mutations,
}
