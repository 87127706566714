import { G_PRODUCT_TYPE_IX, G_PRODUCT_TYPE_MCR2, G_PRODUCT_TYPE_MEGAPORT, G_PRODUCT_TYPE_MVE, G_PRODUCT_TYPE_VXC } from '@/Globals'

const VALID_PRODUCT_FILTERS = [G_PRODUCT_TYPE_MEGAPORT, G_PRODUCT_TYPE_MCR2, G_PRODUCT_TYPE_MVE, G_PRODUCT_TYPE_VXC, G_PRODUCT_TYPE_IX]
const VALID_TERM_FILTERS = [1, 12, 24, 36]

const coreState = () => ({
  productFilters: [],
  termFilters: [],
  textFilter: '',
})

const coreGetters = {
  /** Boolean indicator for if a filter has been applied. If this is false, matchingServices will be an empty array */
  filterApplied: state => state.productFilters.length > 0
    || state.termFilters.length > 0
    || !!state.textFilter,

  /** A list of service UUIDs for services that match the applied filters. Will be an empty array if no filter is applied */
  matchingServices(state, getters) {
    if (!getters.filterApplied) return []

    // Guarantee our matching values are added only once
    const matching = new Set()
    const lowerCaseFilter = state.textFilter.toLocaleLowerCase()

    // Work out which ports, connections or sublags match the text filter
    for (const port of getters.primaryPorts) {
      // Check associated IXs (if they're not being filtered out)
      if (state.productFilters.length < 1 || state.productFilters.includes(G_PRODUCT_TYPE_IX)) {
        for (const ix of port.associatedIxs) {
          const ixDescription = [ix.productName, ix.productUid, ix.rateLimit].join(' ').toLocaleLowerCase()
          if (!state.textFilter || ixDescription.includes(lowerCaseFilter)) {
            matching.add(ix.productUid)
          }
        }
      }

      // Check associated VXCs (if they're not being filtered out)
      if (state.productFilters.length < 1 || state.productFilters.includes(G_PRODUCT_TYPE_VXC)) {
        for (const vxc of port.associatedVxcs) {
          // Skip vxcs that don't match the term filters (if there are any)
          if (state.termFilters.length > 0 && !state.termFilters.includes(vxc.contractTermMonths ?? vxc.term)) continue

          const vxcDescription = [vxc.productName, vxc.productUid, vxc.connectType, vxc.rateLimit].join(' ').toLocaleLowerCase()
          if (!state.textFilter || vxcDescription.includes(lowerCaseFilter)) {
            matching.add(vxc.productUid)
          }
        }
      }

      // Skip products that don't match the type filters (if there are any)
      if (state.productFilters.length > 0 && !state.productFilters.includes(port.productType)) continue

      // Skip products that don't match the term filters (if there are any)
      if (state.termFilters.length > 0 && !state.termFilters.includes(port.contractTermMonths ?? port.term)) continue

      // Filter the port itself based on the text filter, if it's defined
      if (state.textFilter) {
        // If it matches on location, then both the port and all associated lag ports will match the filter
        const locationStr = port._location ? [port._location.name, port._location.country].join(' ').toLocaleLowerCase() : ''
        if (locationStr.includes(lowerCaseFilter)) {
          matching.add(port.productUid)
          if (port._subLags) {
            for (const lag of port._subLags) {
              matching.add(lag.productUid)
            }
          }

        // Otherwise check port and sub-lags individually for text match on description
        } else {
          // Check primary port
          const portDescription = [port.productName, port.productUid].join(' ').toLocaleLowerCase()
          if (portDescription.includes(lowerCaseFilter)) {
            matching.add(port.productUid)
          }

          // Check subLags
          if (port._subLags) {
            for (const lag of port._subLags) {
              const lagDescription = [lag.productName, lag.productUid].join(' ').toLocaleLowerCase()
              if (lagDescription.includes(lowerCaseFilter)) {
                matching.add(lag.productUid)
              }
            }
          }
        }

      // Otherwise automatically add the port if we're filtering on it in other ways
      } else {
        matching.add(port.productUid)
        if (port._subLags) {
          for (const lag of port._subLags) {
            matching.add(lag.productUid)
          }
        }
      }
    }
    return Array.from(matching)
  },

  /** Returns a list of all "primary" port-likes, i.e. filtering out child ports of a LAG */
  primaryPorts: (_state, _getters, rootState) => rootState.Services.services
    .filter(port => {
      // Filter out ports that are part of a link aggregation group (LAG)
      // but are not the primary port to avoid duplication.
      // The other ports that are part of the LAG will be displayed as subLags of the primary port.
      if (port.aggregationId && port.lagPrimary === false) {
        return false
      }
      // Filter out ports that are being added to an existing LAG
      // to avoid duplication since they will also be categorised
      // and displayed as subLags of the primary port.
      if (port.lagPortCount && port.aggregationId) {
        return false
      }
      return true
    }),

  /** Returns the service count for the current company after deduplicating private vxc records */
  uniqueServiceCount(_state, _getters, _rootState, rootGetters) {
    const uuids = rootGetters['Services/allMyServices'].map(service => service.productUid)
    const dedupedUuids = new Set(uuids)
    return Array.from(dedupedUuids).length
  },

  /** The currently applied filters, formatted for setting the query parameters on a route */
  urlQueryFilters(state) {
    const queryObject = {}
    if (state.productFilters.length > 0) queryObject.product = state.productFilters
    if (state.termFilters.length > 0) queryObject.term = state.termFilters
    if (state.textFilter) queryObject.search = state.textFilter
    return queryObject
  },
}

const actions = {
  logout({ getters, commit }) {
    if (getters.filterApplied) {
      commit('clearFilters')
    }
  },
}

const parseArrayParam = param => {
  if (!param) return []
  if (!Array.isArray(param)) return [param]
  return param
}

const mutations = {
  /** Updates the filters in state to match the filters defined in the given url query parameters. If there are no filter params, state is not changed */
  applyUrlQueryFilters(state, queryParams) {
    if (!queryParams.product
      && !queryParams.term
      && !queryParams.search) return

    state.productFilters = parseArrayParam(queryParams.product)
      .filter(product => VALID_PRODUCT_FILTERS.includes(product))
    state.termFilters = parseArrayParam(queryParams.term)
      .map(Number)
      .filter(term => VALID_TERM_FILTERS.includes(term))
    state.textFilter = typeof queryParams.search === 'string' ? queryParams.search : ''
  },
  /** Resets all filters to their default values */
  clearFilters(state) {
    state.productFilters = []
    state.termFilters = []
    state.textFilter = ''
  },
  /** Sets the value of the product filter */
  setProductFilters(state, productFilters) {
    state.productFilters = productFilters
  },
  /** Sets the value of the term filter */
  setTermFilters(state, termFilters) {
    state.termFilters = termFilters
  },
  /** Sets the value of the text filter */
  setTextFilter(state, textFilter) {
    state.textFilter = textFilter
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters: coreGetters,
  actions,
  mutations,
}
