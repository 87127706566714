const buildProviders = {
  whitelabelName: import.meta.env.VITE_WHITELABEL_NAME,
  layout: {
    integratedFooter: import.meta.env.VITE_INTEGRATED_HEADER === 'true',
    integratedHeader: import.meta.env.VITE_INTEGRATED_FOOTER === 'true',
  },
  disabledFeatures: {
    showPrices: import.meta.env.VITE_DISABLE_SHOW_PRICES === 'true',
    billingGraphs: import.meta.env.VITE_DISABLE_BILLING_GRAPHS === 'true',
    promoCodes: import.meta.env.VITE_DISABLE_PROMO_CODES === 'true',

    pusher: import.meta.env.VITE_DISABLE_INTEGRATION_PUSHER === 'true',

    addPort: import.meta.env.VITE_DISABLE_ADD_PORT === 'true',
    addPortToLag: import.meta.env.VITE_DISABLE_ADD_PORT_TO_LAG === 'true',
    locking: import.meta.env.VITE_DISABLE_LOCKING === 'true',
    downloadLoa: import.meta.env.VITE_DISABLE_DOWNLOAD_LOA === 'true',

    // If configuredServices is disabled, not only does it not show up in the sidebar,
    // it also implies that it automatically completes the purchase rather than just
    // configuring it. The translations should also reflect the wording you want at
    // the end of the configuration process. It also implies that you can't save
    // configurations either.
    configuredServices: import.meta.env.VITE_DISABLE_CONFIGURED_SERVICES === 'true',

    marketplace: import.meta.env.VITE_DISABLE_MARKETPLACE === 'true',

    dashboard: import.meta.env.VITE_DISABLE_DASHBOARD === 'true',
    knowledgeBase: import.meta.env.VITE_DISABLE_DOCUMENTATION === 'true',
    footer: import.meta.env.VITE_DISABLE_FOOTER === 'true',
    quickConnect: import.meta.env.VITE_DISABLE_QUICKCONNECT === 'true',
    expandedServices: import.meta.env.VITE_DISABLE_EXPANDED_SERVICES === 'true',
    salesforceChat: import.meta.env.VITE_DISABLE_INTEGRATION_SALESFORCE_CHAT === 'true',
    pageTitles: import.meta.env.VITE_DISABLE_TITLE_UPDATE === 'true',
    megaportMarketing: import.meta.env.VITE_DISABLE_MEGAPORT_MARKETING === 'true',
    announceKit: import.meta.env.VITE_DISABLE_INTEGRATION_ANNOUNCEKIT === 'true',

    productAMSIX: import.meta.env.VITE_DISABLE_PRODUCT_AMSIX === 'true',
    productIX: import.meta.env.VITE_DISABLE_PRODUCT_IX === 'true',
    productMegaportInternet: import.meta.env.VITE_DISABLE_PRODUCT_MEGAPORT_INTERNET === 'true',
  },
}

export default buildProviders
