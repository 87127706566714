import integrations from '@/third-party-integrations/integrations.js'

export class GoogleTagManager {
  #disabled = import.meta.env.VITE_DISABLE_INTEGRATION_GOOGLE_TAG_MANAGER === 'true'

  /**
   * @param {Object} appConfiguration The environment settings loaded from environment.js
   */
  init(appConfiguration) {
    if (this.#disabled) return

    if (!appConfiguration.googleTagManager) {
      integrations.sentry.captureMissingConfigException('Google Tag Manager')
      this.#disabled = true
      return
    }

    window.dataLayer = window.dataLayer || []
    this.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })
    const currentTopScript = document.getElementsByTagName('script')[0]
    let googleTagManagerScript = document.createElement('script')
    googleTagManagerScript.type = 'text/javascript'
    googleTagManagerScript.src = `https://www.googletagmanager.com/gtm.js?id=${appConfiguration.googleTagManager.containerId}`
    googleTagManagerScript.async = true
    currentTopScript.parentNode.insertBefore(googleTagManagerScript, currentTopScript)
  }

  /**
   * Generic method to get data to the data layer to be picked up by GTM
   */
  push() {
    if (this.#disabled) return

    window.dataLayer.push(arguments)
  }
}
