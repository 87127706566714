import sdk from '@megaport/api-sdk'

// Initial state
const coreState = {
  markets: null,
  supplies: null,
  suppliesReady: false,
}

const coreGetters = {
  markets(state, getters, rootState, rootGetters) {
    if (!rootGetters['Company/hasCompany']) {
      return []
    }
    return state.markets ? state.markets.map(market => {
      market.supply = getters.supplies.find(supply => market.id === supply.firstPartyId)
      return market
    }) : []
  },
  supplies(state, getters, rootState, rootGetters) {
    if (!rootGetters['Company/hasCompany']) {
      return []
    }
    return state.supplies || []
  },
  supplyRegions(state, getters) {
    return getters.supplies.map(s => s.region)
  },
}

const actions = {
  /**
   * Retrieves a company's markets from server and mutates the state.
   *
   * @param {object} context (store context)
   */
  async syncMarkets(context) {
    context.commit('setMarkets', [])
    try {
      const markets = await sdk.instance.lists('markets')
      markets.sort((a, b) => (a.country.toLowerCase() < b.country.toLowerCase() ? -1 : 1))
      context.commit('setMarkets', markets)
    } catch (error) {
      // TODO: Improve error processing
      if (!error.handled) {
        console.warn(error)
        throw error
      }
    }
  },
  /**
   * Retrieves a company's supplies from server and mutates the state.
   *
   * @param {object} context (store context)
   */
  syncSupplies(context) {
    context.commit('setSupplies', [])
    return new Promise((resolve, reject) => {

      sdk.instance.markets()
        .get()
        .then(res => {
          context.commit('setSupplies', res)
          return resolve()
        })
        .catch(err => {
          // TODO: Improve error processing
          console.warn(err)
          return reject(err)
        })
        .finally(() => {
          context.commit('setSuppliesReady', true)
        })
    })
  },
  /**
   * Updates a market's supply via API request and mutating the market's supply state
   * @param {object} context (store context)
   * @param {Object} payload - The new supply object
   */
  postMarketSupply(context, payload) {
    return new Promise((resolve, reject) => {
      if (payload.id) {
        // supply exists, so update it
        sdk.instance.markets(payload.id)
          .update(payload)
          .then(() => {
            context.dispatch('syncSupplies')
              .then(() => {
                context.commit('Notifications/notifyGood', {
                  title: window.mpApp.$t('general.success'),
                  message: window.mpApp.$t('billing-markets.update-success', { country: payload.country }),
                }, {
                  root: true,
                })

                return resolve()
              })
          })
          .catch(err => {
            // TODO: Improve error processing
            context.commit('Notifications/notifyBad', {
              title: window.mpApp.$t('general.error-updating', { thing: window.mpApp.$t('billing-markets.supply') }),
              message: err.message,
            }, {
              root: true,
            })

            return reject(err)
          })
      } else {
        // is new supply
        sdk.instance.markets()
          .create(payload)
          .then(() => {

            context.dispatch('syncSupplies')
              .then(() => {
                context.commit('Notifications/notifyGood', {
                  title: window.mpApp.$t('general.success'),
                  message: window.mpApp.$t('billing-markets.create-success', { country: payload.country }),
                }, {
                  root: true,
                })

                return resolve()
              })

          })
          .catch(err => {
            // TODO: Improve error processing
            context.commit('Notifications/notifyBad', {
              title: window.mpApp.$t('general.error-creating', { thing: window.mpApp.$t('billing-markets.supply') }),
              message: err.message,
            }, {
              root: true,
            })

            return reject(err)
          })
      }
    })
  },

}

const mutations = {
  logout(state) {
    state.markets = null
    state.supplies = null
  },
  setMarkets(state, markets) {
    state.markets = markets
  },
  setSupplies(state, supplies) {
    state.supplies = supplies
  },
  setSuppliesReady(state, tf) {
    state.suppliesReady = tf
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters: coreGetters,
  actions,
  mutations,
}
