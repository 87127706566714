import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import { Settings } from 'luxon'
import store from '@/store/Store.js'
import integrations from '@/third-party-integrations/integrations'

/**
 * This is the master ordered list of languages for the user to select from. This is used internally
 * and in the language selection in the UI.
 *
 * The first language specified is the default and is automatically loaded as the fallback language.
 */
export const languageSelections = [
  { code: 'en', region: 'US', iso: 'en-US', file: 'en-setup.js', name: 'English' },
  { code: 'de', region: 'DE', iso: 'de-DE', file: 'de-setup.js', name: 'Deutsch' },
  { code: 'fr', region: 'FR', iso: 'fr-FR', file: 'fr-setup.js', name: 'Français' },
  { code: 'ja', region: 'JP', iso: 'ja-JP', file: 'ja-setup.js', name: '日本語 (Japanese)' },
  { code: 'es', region: 'ES', iso: 'es-ES', file: 'es-setup.js', name: 'Español' },
  { code: 'pt', region: 'BR', iso: 'pt-BR', file: 'pt-BR-setup.js', name: 'Português Brasileiro' },
]

const loadLanguageMessages = async lang => {
  // Define a glob pattern to import all language files
  const languageModules = import.meta.glob('@/i18n/*.js')
  const languageFile = `/src/i18n/${lang}`
  if (languageModules[languageFile]) {
    const module = await languageModules[languageFile]()
    return module.default
  } else {
    throw new Error(`Language file ${languageFile} not found`)
  }
}

const loadedLanguages = [languageSelections[0].iso]
let i18n = null

/**
 * Get the selected language from cookie/localStorage. Falls back to en-US if not found.
 *
 * @returns The selected language record
 */
const readSelectedLanguage = () => {
  const cookies = new URLSearchParams(document.cookie.replace(/; /g, '&'))
  // Look for one that includes the region, fall back to just language
  const localStore = (window.localStorage.getItem('mp_lang_region') || window.localStorage.getItem('mp_lang')) || undefined
  const resolved = cookies.get('mp_lang_region') || cookies.get('mp_lang') || localStore
  const selectedLanguage = languageSelections.find(lang => lang.iso === resolved || lang.code === resolved)
  // Avoid the possibility of it being set to something we don't know how to translate.
  return selectedLanguage || languageSelections[0]
}

/**
 * Set the language as a cookie as the primary information.
 * Some ad blockers block cookies so mirror to localStorage (if on client).
 *
 * @param {object} lang The selected language iso abbreviation
 */
const writeSelectedLanguage = ({ code, iso }) => {
  // Set lang code
  document.cookie = `mp_lang=${code}; domain=megaport.com; path=/;`
  window.localStorage.setItem('mp_lang', code)
  // Set lang iso
  document.cookie = `mp_lang_region=${iso}; domain=megaport.com; path=/;`
  window.localStorage.setItem('mp_lang_region', iso)
}

/**
 * Uses the cached language definition and sets it as current, or if not cached, dynamically loads the
 * specified language.
 *
 * @param {string} lang Language record from languageSelections
 */
export const setActiveLanguage = async (lang = readSelectedLanguage()) => {
  writeSelectedLanguage(lang)

  if (!loadedLanguages.includes(lang.iso)) {
    const messages = await loadLanguageMessages(lang.file)
    loadedLanguages.push(lang.iso)

    i18n.setLocaleMessage(lang.iso, messages)
    loadedLanguages.push(lang.iso)
  }
  setI18nLanguage(lang.iso)
  store.commit('setLanguage', lang)
}

/**
 * Set the i18n plugin language
 * Makes the specified language work throughout the app.
 *
 * @param {string} language The language abbreviation
 */
export const setI18nLanguage = language => {
  // I18n plugin
  i18n.locale = language
  // Luxon dates
  Settings.defaultLocale = language
  Settings.resetCaches()
  // Html
  updateHtml()
  // PostHog
  integrations.postHog.setSessionData({ language })
  integrations.postHog.setUserData({ 'Latest Language': language })
}

/**
 * Updates the lang attribute on the html and sets an appropriate class on the body so you can adjust formatting
 * for different languages if required.
 */
const updateHtml = () => {
  document.querySelector('html').setAttribute('lang', i18n.locale)

  const removableClasses = []
  document.body.classList.forEach(className => {
    if (className.startsWith('locale-')) {
      removableClasses.push(className)
    }
  })
  for (const className of removableClasses) {
    document.body.classList.remove(className)
  }
  document.body.classList.add(`locale-${i18n.locale}`)
}

const initializeI18n = async () => {
  // We can not load up i18n when running tests because we lose the ability to
  // set up the v-t directive
  if (import.meta.env.VITE_RUNTIME_ENV !== 'test') {
    Vue.use(VueI18n)

    // Load default language messages asynchronously
    const defaultLanguageMessages = await loadLanguageMessages(languageSelections[0].file)
    i18n = new VueI18n({
      locale: readSelectedLanguage().iso,
      fallbackLocale: languageSelections[0].iso,
      messages: { [languageSelections[0].iso]: defaultLanguageMessages },
      silentTranslationWarn: true,
      // postTranslation: str => `X${str}X` // Uncomment this to display everything translated with a prefix & suffix of X
    })
    ElementLocale.i18n((key, value) => i18n.t(key, value))

    // Initialise with the default or preselected active language.
    await setActiveLanguage()
  }
}

export const getI18n = async () => {
  await initializeI18n()
  return i18n
}

export default i18n
