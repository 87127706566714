const needsReload = (state, tf) => {
  state.needsReload = tf
}

const setSidebar = (state, val) => {
  state.showSidebar = val
}

const setLanguage = (state, val) => {
  state.language = val
}

const setG2NewLogin = (state, val) => {
  state.g2NewLogin = val
}

const setIsUrlTriggeredG2Review = (state, val) => {
  state.isUrlTriggeredG2Review = val
}

export default {
  needsReload,
  setSidebar,
  setLanguage,
  setG2NewLogin,
  setIsUrlTriggeredG2Review,
}
