export class Stripe {
  #disabled = import.meta.env.VITE_DISABLE_INTEGRATION_STRIPE === 'true'

  init() {
    if (this.#disabled) return

    const stripeLoader = () => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://js.stripe.com/v3/'
      document.head.appendChild(script)
    }

    if (document.readyState === 'complete') {
      stripeLoader()
    } else {
      window.addEventListener('load', stripeLoader, false)
    }
  }
}
