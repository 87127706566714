import { Amplify } from 'aws-amplify'
import { signInWithRedirect, getCurrentUser, signOut, fetchAuthSession } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import integrations from '@/third-party-integrations/integrations.js'

export class AWSAmplify {
  #disabled = import.meta.env.VITE_DISABLE_INTEGRATION_AMPLIFY === 'true'

  /**
   * @param {Object} appConfiguration The environment settings loaded from environment.js
   */
  init(appConfiguration) {
    if (this.#disabled) return

    if (!appConfiguration.amplify) {
      integrations.sentry.captureMissingConfigException('AWS Amplify')
      this.#disabled = true
      return
    }

    const { amplify: config } = appConfiguration

    const oauth = {
      domain: config.OAUTH_DOMAIN,
      scopes: ['email', 'profile', 'openid'],
      redirectSignIn: [`${window.location.origin}/${config.SIGNIN_CALLBACK}`],
      redirectSignOut: [`${window.location.origin}/${config.SIGNOUT_CALLBACK}`],
      responseType: 'code',
    }

    const awsConfig = {
      Auth: {
        Cognito: {
          // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
          identityPoolId: config.IDENTITY_POOL_ID,
          // REQUIRED - Amazon Cognito Region
          region: config.REGION,
          // OPTIONAL - Amazon Cognito User Pool ID
          userPoolId: config.USER_POOL_ID,
          // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
          userPoolClientId: config.CLIENT_ID,
          loginWith: {
            oauth,
          },
        },
      },
    }

    Amplify.configure(awsConfig)
  }

  federatedSignIn(options = { provider: 'Google' }) {
    if (this.#disabled) return
    return signInWithRedirect(options)
  }

  addHubListener({ signInCallback, signInFailureCallback }) {
    if (this.#disabled) return

    Hub.listen('auth', ({ payload: { data, event } }) => {
      switch (event) {
        case 'signedIn':
          this.getUserSessionToken().then(token => {
            signInCallback && signInCallback(token)
          })
          break
        case 'signInWithRedirect_failure':
          signInFailureCallback && signInFailureCallback(data)
          break
      }
    })
  }

  async getUserSessionToken() {
    const session = await fetchAuthSession()
    return {
      access_token: session.tokens.accessToken.toString(),
      id_token: session.tokens.idToken.toString(),
    }
  }

  isUserAuthenticated() {
    return new Promise((resolve, reject) => {
      getCurrentUser({
        forceRefresh: false,
      })
        .then(user => {
          resolve(user)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  async refreshSession() {
    if (this.#disabled) return

    const session = await fetchAuthSession({ forceRefresh: true })
    return session
  }

  logout() {
    signOut()
      .then(() => {})
      .catch(err => {
        Promise.reject(err)
      })
  }
}
