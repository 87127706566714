import posthog from 'posthog-js'
import captureSentryError from '@/utils/CaptureSentryError.js'
import integrations from '@/third-party-integrations/integrations.js'

export class PostHog {
  #disabled = import.meta.env.VITE_DISABLE_INTEGRATION_POST_HOG === 'true'
  #config = {}

  // We use a promise to delay certain method calls until the integration is ready
  #markReady
  #ready = new Promise(resolve => { this.#markReady = resolve })

  async #readyAndDisabled() {
    // Exit out early in case the integration is disabled
    if (this.#disabled) return true

    await this.#ready
    return this.#disabled
  }

  /**
   * Sets up the PostHog integration,
   * @param {Object} appConfiguration The environment settings loaded from `environment.js`.
   */
  init(appConfiguration) {
    if (this.#disabled) return

    if (!appConfiguration.postHog) {
      integrations.sentry.captureMissingConfigException('PostHog')
      this.#disabled = true
      return
    }
    this.#config = appConfiguration.postHog
  }

  /**
   * Function for associating a user with the current PostHog session.
   * @param {Object} payload The credentials payload returned from authenticating with API-SDK.
   * @see https://posthog.com/docs/libraries/js#identifying-users
   */
  setUser(payload) {
    if (this.#disabled) return

    try {
      posthog.init(this.#config.token, {
        api_host: this.#config.host,
        autocapture: this.#config.autocapture ?? false,
        // Disable automatic page view tracking, as we handle this manually
        capture_pageview: false,
        // Disable functionality we don't use
        disable_session_recording: true,
        advanced_disable_feature_flags: true,
        // As per our privacy policy, PostHog is not allowed to track users without their consent. You may still notice
        // some posthog cookies when testing locally, this is allegedly only for developers and not for end users.
        // https://github.com/PostHog/posthog-js/issues/614#issuecomment-1804204706
        disable_persistence: true,
      })

    } catch (error) {
      // In the unlikely event of an error, log it as a sentry error, and set posthog to disabled
      captureSentryError(error)
      this.#disabled = true
      this.#markReady()
      return
    }

    const { username, companyUid, companyName, accountType } = payload
    posthog.identify(username, { companyUid, companyName, accountType })
    this.#markReady()
  }

  /**
   * Function for associating arbitrary data with a session. The data will be included in all sent events from this session.
   * @param {Object} payload An object of key/value pairs to be included in all events sent in the session.
   * @see https://posthog.com/docs/libraries/js#super-properties
   */
  async setSessionData(payload) {
    if (await this.#readyAndDisabled()) return

    posthog.register(payload)
  }

  /**
   * Function for associating arbitrary data with a user's record. If the same data is set multiple times, the latest
   * value will be recorded.
   * @param {Object} payload An object of key/value pairs to be associated with the user.
   * @see https://posthog.com/docs/libraries/js#setting-person-properties
   */
  async setUserData(payload) {
    if (await this.#readyAndDisabled()) return

    posthog.setPersonProperties(payload)
  }

  /**
   * Function for capturing a custom event that will be recorded against the user in PostHog. If the user is not signed
   * in, the event will be sent once the user signs in.
   * @param {string} event The code of the event
   * @param {Object} [payload] An optional payload containing an object of key/value pairs to be associated with the event.
   * @see https://posthog.com/docs/libraries/js#capturing-events
   */
  async captureEvent(event, payload) {
    if (await this.#readyAndDisabled()) return

    posthog.capture(event, payload)
  }

  /**
   * Function for capturing a page view event that will be recorded against the user in PostHog. Ignored if the user is
   * not signed in.
   * @see https://posthog.com/docs/libraries/vue-js#capturing-pageviews
   */
  recordPageView() {
    if (this.#disabled) return

    // This is in it's own function so we're not queueing multiple page view events to occur upon login
    posthog.capture('$pageview')
  }

  /**
   * Ends the current PostHog session for the user
   * @see https://posthog.com/docs/libraries/js#handling-logging-out
   */
  clearUser() {
    if (this.#disabled) return

    posthog.reset()
  }
}
