<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :title="$t('checkout.order')"
    :modal-append-to-body="false">
    <template #footer>
      <el-button data-name="services-close"
        @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
      <template v-if="!deploying">
        <el-button v-if="needsSetup === 'no-company'"
          name="no-company"
          type="primary"
          @click="$router.push('/company/profile', () => {}); setVisible(false);">
          {{ $t('checkout.set-up-company') }}
        </el-button>
        <el-button v-if="needsSetup === 'no-markets'"
          name="no-markets"
          type="primary"
          @click="$router.push('/company/markets', () => {}); setVisible(false);">
          {{ $t('checkout.register-markets') }}
        </el-button>
        <el-button v-if="readyToOrder"
          data-testid="confirm-order-button"
          data-name="services-deploy"
          type="primary"
          @click="deploy">
          {{ $t('checkout.order-now') }}
        </el-button>
      </template>
    </template>

    <template v-if="needsSetup">
      <p>{{ $t('checkout.need-setup') }}</p>
      <p v-if="needsSetup === 'no-company'">
        {{ $t('checkout.need-company') }}
      </p>
      <p v-if="needsSetup === 'no-setup-permissions1'">
        {{ $t('checkout.need-company-permissions') }}
      </p>
      <p v-if="needsSetup === 'no-markets'">
        {{ $t('checkout.need-markets') }}
      </p>
      <p v-if="needsSetup === 'no-setup-permissions2'">
        {{ $t('checkout.need-markets-permissions') }}
      </p>
    </template>
    <template v-else-if="errorList || validationMessage">
      <h4 v-if="validationMessage"
        class="text-align-center">
        {{ validationMessage }}
      </h4>

      <template v-if="errorList">
        <!-- Validation Error Service Card -->
        <div v-for="(error, index) in errorList"
          :key="index"
          class="service-profile service-summary">
          <!-- Service Icon -->
          <mu-mega-icon v-if="error.service"
            :icon="getIconFor(error.service)"
            class="product-icon" />
          <!-- Service Details -->
          <div>
            <!-- Service Name -->
            <h4 v-if="error.service">
              {{ error.service.productName }}
            </h4>
            <!-- Service Error Message -->
            <div class="color-danger ml-1">
              {{ error.errorMessage }}
            </div>
          </div>
          <!-- Service Edit Link -->
          <div v-if="error.editLink"
            class="ml-auto mr-1-4">
            <el-button type="primary"
              @click="$router.push(error.editLink, () => {}); setVisible(false)">
              <i class="fal fa-edit"
                aria-hidden="true" /> {{ $t('general.edit') }}
            </el-button>
          </div>
          <!-- Service Error Information -->
          <div v-if="error.information"
            class="ml-1">
            {{ error.information }}
          </div>
        </div>
      </template>
    </template>
    <div v-else-if="!serviceList || serviceList.length === 0"
      class="text-align-center">
      <img src="@/assets/loader.gif"
        :alt="$t('images.loading-logo')"
        data-name="validating-spinner">
      <h3>{{ $t('checkout.validating') }}</h3>
    </div>
    <template v-else>
      <div v-show="deploying"
        class="text-align-center">
        <img src="@/assets/loader.gif"
          :alt="$t('images.loading-logo')"
          data-name="deploying-spinner">
        <h3>{{ deployingMessage }}</h3>
      </div>
      <div v-show="!deploying">
        <div v-for="service in displayList"
          :key="service.productUid"
          class="service-profile">
          <!-- Service Card -->
          <div class="service-summary"
            :data-service="service.serviceName">
            <!-- Service Icon -->
            <mu-mega-icon :icon="getIconFor(service)"
              class="product-icon" />
            <!-- Service Details -->
            <div>
              <!-- Service Name -->
              <h4 data-name="service-name">
                {{ service.serviceName }}
              </h4>
              <!-- Service Term -->
              <div v-if="service.raw.term"
                data-name="service-term">
                {{ $tc(isPartnerVantage ? 'partner-vantage.subscription-months' : 'checkout.minimum-term-months', minimumTerm(service.raw), { count: service.raw.term }) }}
              </div>
              <!-- Service No Term -->
              <div v-else>
                {{ $t('checkout.no-term') }}
              </div>
            </div>
            <!-- Service Pricing -->
            <div v-if="showPricing"
              class="prices">
              <!-- Monthly Rate -->
              <div v-if="service.displayPrice"
                class="text-align-right"
                data-name="service-rate">
                <span class="d-block">{{ $t('pricebook.monthly-rate') }}</span>
                <span class="d-block font-weight-500">{{ service.displayPrice }}</span>
              </div>
              <!-- Promo Code -->
              <template v-if="!disabledFeatures.promoCodes && !hidePromoCode">
                <!-- Current Promo Code -->
                <div v-if="service.promoValidationStatus === STATUS_CHECK_SUCCEEDED"
                  class="text-align-right">
                  {{ $t('checkout.promo') }}: {{ service.promoCodeDesc }} <a class="cursor-pointer"
                    @click="removePromo(service)">{{ $t('general.remove') }}</a>
                </div>
                <!-- Add Promo Code -->
                <div v-else
                  class="text-align-right">
                  <a class="cursor-pointer"
                    @click="service.showPromoCodeEntry = !service.showPromoCodeEntry">{{ $t('checkout.add-promo-code') }}</a>
                </div>
              </template>
            </div>
          </div>

          <el-collapse-transition>
            <div v-if="service.showPromoCodeEntry"
              class="service-details text-align-center">
              {{ $t('checkout.promo-code') }}:
              <el-input :ref="'promo.' + service.productUid"
                v-model="service.promoCode"
                size="small"
                class="width-130px mr-1" />
              <el-button size="small"
                @click="service.showPromoCodeEntry = false">
                {{ $t('checkout.hide-promo') }}
              </el-button>
              <el-button type="primary"
                size="small"
                @click="addPromoCode(service)">
                {{ $t('checkout.add-promo') }}
              </el-button>
              <el-collapse-transition>
                <div v-if="service.promoValidationStatus !== STATUS_UNCHECKED">
                  <div v-if="service.promoValidationStatus === STATUS_CHECKING"
                    class="color-warning">
                    {{ $t('checkout.checking-promo') }}
                  </div>
                  <div v-if="service.promoValidationStatus === STATUS_CHECK_SUCCEEDED"
                    class="color-success">
                    {{ $t('checkout.promo-validated') }}
                  </div>
                  <div v-if="service.promoValidationStatus === STATUS_CHECK_FAILED"
                    class="color-danger">
                    {{ service.validationErrorMessage }}
                  </div>
                </div>
              </el-collapse-transition>
            </div>
          </el-collapse-transition>
        </div>

        <div v-if="showPricing && includesPrice"
          class="text-align-right">
          {{ $t('pricebook.excludes-tax') }}
        </div>

        <div v-for="(message, index) in checkoutMessages"
          :key="index"
          class="notices">
          <strong>{{ $t('general.important-information') }}</strong>
          <br>
          <span v-html="message" /> <!-- eslint-disable-line vue/no-v-html -->
        </div>

        <div class="notices">
          <strong>{{ $t('general.important-information') }}</strong>
          <br>
          <span v-html="legalParagraph" /> <!-- eslint-disable-line vue/no-v-html -->
        </div>

        <div v-auth-hide="canDeploy">
          {{ $t('checkout.no-deploy') }}
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
// External tools
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import sdk from '@megaport/api-sdk'
// Internal tools
import { moneyFilter, markdownLinkToHtml, deepClone, removeUnderscoreKeys } from '@/helpers.js'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'
// Integrations
import integrations from '@/third-party-integrations/integrations.js'
import { captureEvent } from '@/utils/analyticUtils'
// Globals
import {
  LEGAL_PARAGRAPH_MANAGED_PARTNER,
  LEGAL_PARAGRAPH_MANAGED_USER,
  G_PRODUCT_TYPE_MCR2,
  G_PRODUCT_TYPE_MVE,
  G_PRODUCT_TYPE_VXC,
  CISCO_VENDOR,
} from '@/Globals.js'

// This is set up for use of visible.sync, so when the dialog needs to close it emits the update message.
export default {
  name: 'CheckoutConfiguredServices',

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      validationMessage: null,
      serviceList: [],
      displayList: [],
      errorList: null,
      promoCodes: [],
      deploying: false,
      deployingMessage: null,
      orderConfig: [],
      serviceOrderUid: null,
      // Constants
      STATUS_UNCHECKED: 0,
      STATUS_CHECKING: 1,
      STATUS_CHECK_FAILED: 2,
      STATUS_CHECK_SUCCEEDED: 3,
    }
  },

  computed: {
    ...mapState('Company', { company: 'data' }),
    ...mapState('Services', ['services', 'locations']),
    ...mapGetters('ApplicationContext', {
      isManagedContext: 'isManagedContext',
      callContext: 'companyUid',
    }),
    ...mapGetters('Auth', ['isNewUser', 'isPartnerAccount', 'isManagedAccount', 'hasAuth', 'isDirectAccount', 'hasOrderedServices', 'isPartnerVantage']),
    ...mapGetters('Company', ['companyUid', 'companySupplyRegions', 'hasCompany']),
    ...mapGetters('Markets', ['supplyRegions']),
    ...mapGetters('Services', ['allServicesUidDictionary', 'findService', 'findPort', 'myConnections']),
    /**
     * Returns the order configuration payload with private underscored keys removed before sending to API.
     */
    orderConfigPayload() {
      return this.orderConfig.map(service => {
        const clonedObj = deepClone(service)
        return removeUnderscoreKeys(clonedObj)
      })
    },
    showPricing() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
    includesPrice() {
      return this.displayList.some(item => item.displayPrice)
    },
    legalParagraph() {
      if (this.isPartnerAccount) return LEGAL_PARAGRAPH_MANAGED_PARTNER

      if (this.isManagedAccount) return LEGAL_PARAGRAPH_MANAGED_USER

      return import.meta.env.VITE_LEGAL_PARAGRAPH
    },
    readyToOrder() {
      return !this.needsSetup && !this.errorList && !this.validationMessage && this.serviceList && this.serviceList.length && this.canDeploy
    },
    needsSetup() {
      // No setup required when logged in as a managed account
      if (this.isManagedAccount) return false
      if (this.isManagedContext && !this.hasAuth('financials')) return false

      const hasPrivs = this.isNewUser || this.hasAuth('company_admin')
      if (!this.hasCompany) {
        return hasPrivs ? 'no-company' : 'no-setup-permissions1'
      }
      const hasPrivs2 = this.hasAuth('company_admin')
      if (this.companySupplyRegions.length === 0 && this.supplyRegions.length === 0) {
        return hasPrivs2 ? 'no-markets' : 'no-setup-permissions2'
      }
      return false
    },
    canDeploy() {
      return this.hasAuth('place_order')
    },
    hidePromoCode() {
      return this.isManagedAccount && !this.isFeatureEnabled('PAYS_BILL')
    },
    checkoutMessages() {
      // As per ENG-6387, for VXCs that either start or end in a location that has a message,
      // we need to display the message if they are crossing to a different metro. Also if a Port
      // or MCR is being ordered at a location requiring a message, it will be displayed, and if
      // an IX is being connected to a previously deployed port that requires a message, we will
      // display the message.

      // Get the product IDs of the products that are being checked out
      const serviceUids = this.serviceList.map(service => {
        return service.productUid
      })
      // Find the VXC and IX products in the services so we can get the full information about the service,
      // and from that, extract the location of both a and b ends.
      const locationPairs = []
      const messages = []

      for (const port of this.services) {
        // See if the port itself is being checked out, or an IX connected to the port
        // (an IX doesn't have a location id itself, so only the port location is relevant)
        const matchingIx = port.associatedIxs.find(ix => {
          return serviceUids.includes(ix.productUid)
        })

        if (matchingIx || serviceUids.includes(port.productUid)) {
          const location = this.locations.find(loc => {
            return loc.id === port.locationId
          })
          if (location) {
            const orderingMessage = location.ordering_message ? location.ordering_message.trim() : null
            if (orderingMessage && !messages.includes(orderingMessage)) {
              messages.push(orderingMessage)
            }
          }
        }

        const matchingVxc = port.associatedVxcs.find(vxc => {
          return serviceUids.includes(vxc.productUid)
        })
        if (matchingVxc) {
          const aLocation = matchingVxc.aEnd.locationId
          const bLocation = matchingVxc.bEnd.locationId
          // Only add a location pair once (order of a end or b end doesn't matter)
          const matchingPair = locationPairs.find(pair => {
            return (pair[0] === aLocation && pair[1] === bLocation) || (pair[0] === bLocation && pair[1] === aLocation)
          })
          if (!matchingPair) {
            locationPairs.push([aLocation, bLocation])
          }
        }
      }

      // Now we have a list of location pairs. Now see if any of them generate messages.
      for (const pair of locationPairs) {
        const aLocation = this.locations.find(location => {
          return location.id === pair[0]
        })
        const bLocation = this.locations.find(location => {
          return location.id === pair[1]
        })
        // Should always be true, but check, just in case locations changed.
        if (aLocation && bLocation) {
          // Only generate messages if the two ends are in a different metro.
          if (aLocation.metro !== bLocation.metro) {
            const aOrderingMessage = aLocation.ordering_message ? aLocation.ordering_message.trim() : null
            const bOrderingMessage = bLocation.ordering_message ? bLocation.ordering_message.trim() : null

            if (aOrderingMessage && !messages.includes(aOrderingMessage)) {
              messages.push(aOrderingMessage)
            }
            if (bOrderingMessage && !messages.includes(bOrderingMessage)) {
              messages.push(bOrderingMessage)
            }
          }
        }
      }

      const convertedMessages = []
      for (const message of messages) {
        const replacedText = markdownLinkToHtml(message, true, false, true)
        convertedMessages.push(replacedText)
      }
      return convertedMessages
    },
  },

  watch: {
    'company.markets'() {
      if (this.hasCompany) {
        this.initCart()
      }
    },
    visible: {
      immediate: true,
      handler() {
        if (this.visible) {
          this.initCart()
        }
      },
    },
  },

  methods: {
    ...mapActions('Notifications', ['showIbmReminderModal']),
    ...mapActions('Services', ['deleteCartFromServer', 'getMyServices', 'setDeployingCart', 'clearCartFromLocalStorage', 'setPeriodicRefreshEnabled']),
    ...mapMutations('Notifications', ['notifyBad', 'notifyGood']),
    ...mapMutations('Services', ['changeProvisioningStatus']),
    ...mapMutations('Auth', ['setHasOrderedServicesTrue']),

    updateDisplayList() {
      this.displayList = this.serviceList.map(service => {
        const s = Object.assign({}, service)

        if (![undefined, null].includes(s?.price?.monthlyRate)) {
          s.displayPrice = moneyFilter(s.price.monthlyRate * s.multiplier, s.price.currency)
        }

        s.raw = this.allServicesUidDictionary[s.productUid]
        const code = this.promoCodes.find(p => p.productUid === s.productUid) || {}
        s.promoCode = code.code || ''
        if (s.promoCode) {
          s.promoCodeDesc = code.description
          s.promoValidationStatus = this.STATUS_CHECK_SUCCEEDED
        } else {
          s.promoValidationStatus = this.STATUS_UNCHECKED
        }
        s.validationErrorMessage = ''
        s.showPromoCodeEntry = false
        return s
      })
    },
    setVisible(newValue) {
      if (newValue === false) {
        const serviceOrderId = localStorage.getItem(`_mpCartId_${this.companyUid}`)
        if (serviceOrderId) {
          this.deleteCartFromServer({ serviceOrder: serviceOrderId, suppressSuccessMessages: true })
          localStorage.removeItem(`_mpCartId_${this.companyUid}`)
        }
      }
      this.$emit('update:visible', newValue)
    },
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    addPromoCodeToOrder(productUid, promoCode) {
      this.orderConfig = this.orderConfig.map(item => {
        // add promo code to the service
        if (item.productUid === productUid) {
          return {
            ...item,
            promoCode: promoCode,
          }
        }

        // add promo code to vxc
        if (item.associatedVxcs.length) {
          return {
            ...item,
            associatedVxcs: item.associatedVxcs.map(vxc => {
              if (vxc.productUid === productUid) {
                return {
                  ...vxc,
                  promoCode: promoCode,
                }
              }

              return vxc
            }),
          }
        }

        // add promo code to ix
        if (item.associatedIxs.length) {
          return {
            ...item,
            associatedIxs: item.associatedIxs.map(ix => {
              if (ix.productUid === productUid) {
                return {
                  ...ix,
                  promoCode: promoCode,
                }
              }

              return ix
            }),
          }
        }

        return item
      })
    },
    addPromoCode(service) {
      if (service.promoCode) {
        service.promoValidationStatus = this.STATUS_CHECKING
        sdk.instance
          .promoCode(service.promoCode)
          .then(code => {
            if (!code.productTypes.includes(service.raw.productType)) {
              service.promoValidationStatus = this.STATUS_CHECK_FAILED
              service.validationErrorMessage = this.$t('checkout.invalid-code')
              return
            }

            // map the promo code to the order service
            this.addPromoCodeToOrder(service.productUid, service.promoCode)

            const inCodeList = this.promoCodes.find(p => p.productUid === service.productUid)
            if (!inCodeList) {
              this.promoCodes.push({
                productUid: service.productUid,
                code: code.code,
                description: code.description,
              })
            } else {
              inCodeList.code = code.code
              inCodeList.description = code.description
            }

            service.promoCodeDesc = code.description
            service.promoValidationStatus = this.STATUS_CHECK_SUCCEEDED
            // Now that it's been validated, we can close the entry area
            service.showPromoCodeEntry = false
          })
          .catch(err => {
            service.promoValidationStatus = this.STATUS_CHECK_FAILED
            if (typeof err === 'string' && err !== '') {
              service.validationErrorMessage = err
            } else if (typeof err !== 'string' && err.data?.error) {
              service.validationErrorMessage = err.data.error
            } else {
              service.validationErrorMessage = this.$t('checkout.code-not-confirmed')
              captureSentryError(err)
            }
          })
      } else {
        service.promoValidationStatus = this.STATUS_CHECK_FAILED
        service.validationErrorMessage = this.$t('checkout.null-code')
      }
    },
    initCart() {
      if (this.needsSetup) return
      // Reset everything to defaults
      this.validationMessage = null
      this.serviceList = []
      this.displayList = []
      this.errorList = null
      this.promoCodes = []

      const cart = JSON.parse(localStorage.getItem(`_mpCart_${this.companyUid}`))
      this.serviceOrderUid = JSON.parse(localStorage.getItem(`_mpCartId_${this.companyUid}`))

      // TODO: Remove this nasty hack and do it properly
      // Since the stuff that is sent to the API is read directly from the data that is stored in localstorage, it doesn't
      // make any difference what may have been set in memory in the Store. We only see the localstorage data.
      // Based on my testing, it appears that the problem only happens when the dealUid is set to 'None' on the port-like
      // objects, but we will clean it on the VXCs and IXs too just to be sure.
      for (const portLike of cart) {
        if (portLike.dealUid === 'None') {
          portLike.dealUid = null
        }

        for (const vxc of portLike.associatedVxcs) {
          if (vxc.dealUid === 'None') {
            vxc.dealUid = null
          }
        }

        for (const ix of portLike.associatedIxs) {
          if (ix.dealUid === 'None') {
            ix.dealUid = null
          }
        }
      }

      // Get the order configuration from the order details
      this.orderConfig = cart

      // Delete the Appliance Mode field from the vendor configuration for in-DESIGN Cisco MVEs since the BE has no use for it
      this.orderConfig = this.orderConfig.map(configuredService => {
        if (configuredService.provisioningStatus === this.G_PROVISIONING_DESIGN) {
          const serviceIsCiscoMve = configuredService.productType === G_PRODUCT_TYPE_MVE && configuredService.vendorConfig._vendor === CISCO_VENDOR

          if (serviceIsCiscoMve) delete configuredService.vendorConfig.applianceMode
        }

        return configuredService
      })

      this.validate()
    },
    validate() {
      sdk.instance
        .networkDesign()
        .validate(this.orderConfigPayload)
        .then(validationResults => {
          this.serviceList = validationResults.map(thisResult => {
            const thisService = this.findService(thisResult.productUid)

            if (!thisService) {
              // This should never happen, but somehow it has happened 4 times
              // under sentry issue 4694 so try to gather some relevant information
              const error = new Error('Missing service during validation')
              const connectionInfo = this.myConnections.map(({ productUid, productType }) => ({ serviceUid: productUid, productType }))

              // Temporarily until we understand the issue with order validation
              integrations.sentry.captureException(error, { 
                contexts: { 
                  product: {
                    missingProductUid: thisResult.productUid,
                    validationResult: validationResults,
                    knownServiceInfo: connectionInfo,
                    'X-Call-Context': this.callContext,
                  },
                },
              })
            } else {
              thisResult.productType = thisService.productType
            }

            thisResult.multiplier = thisResult.lagPortCount || 1

            return thisResult
          })
          this.updateDisplayList()

          captureEvent('sidebar.order.click', { configuredServices: this.serviceList.length })
        })
        .catch(err => {
          // We should always get back a data object if it failed,
          // so anything else is a network or other error.
          if (!err.data) {
            captureSentryError(err)
          } else if (typeof err.data.data === 'string') {
            this.validationMessage = this.$t('checkout.unknown-validation-error', { message: err.data.data })

            return
          }

          const allProductUids = this.services.map(service => service.productUid)

          for (const service of this.services) {
            for (const ix of service.associatedIxs) {
              allProductUids.push(ix.productUid)
            }

            for (const vxc of service.associatedVxcs) {
              allProductUids.push(vxc.productUid)
            }
          }

          // Generic message for all other errors not receiving message response from the service
          const genericMessage = this.$t('general.server-error')

          this.validationMessage = err.data?.message || genericMessage
          // Use a reducer so we can skip errors we don't recognise without adding undefined to the error list
          this.errorList = err.data?.data?.reduce((errorList, validationError) => {
            // See if the error relates to a known product UID
            for (const serviceUid of allProductUids) {
              const errorMessage = validationError[serviceUid]

              if (errorMessage) {
                const service = deepClone(this.allServicesUidDictionary[serviceUid])

                // Determine the editLink prefix value based on the service type
                let prefix

                switch (service?.productType) {
                  case this.G_PRODUCT_TYPE_MEGAPORT:
                    prefix = '/create-megaport/port/'
                    break
                  case this.G_PRODUCT_TYPE_MCR2:
                    prefix = '/create-megaport/mcr/'
                    break
                  case this.G_PRODUCT_TYPE_MVE:
                    prefix = '/mve/'
                    break
                  default:
                    prefix = '/create-connection/'
                }

                const editLink = service ? prefix + serviceUid : '/'

                return [...errorList, { serviceUid, errorMessage, service, editLink }]
              }
            }

            // See if it relates to missing markets - expect "markets" and "error" keys
            if (validationError.markets) {
              const props = this.hasAuth('company_admin')
                ? { editLink: '/company/markets' }
                : { information: this.$t('billing-markets.enable-markets-admin') }

              return [...errorList, { ...props, errorMessage: validationError.error }]
            }

            // See if it is related to Missing Technical Support - expect "Missing Technical Support" text in value of "error" key
            if (validationError.error?.includes('Missing Technical Support')) {
              const props = this.hasAuth('company_admin')
                ? { editLink: '/company/support' }
                : { information: this.$t('checkout.need-tech-support-details-by-admin') }

              return [...errorList, { ...props, errorMessage: validationError.error }]
            }

            // These are the only errors we know how to pick up so far
            console.error('Unknown validation error', validationError)

            return errorList
          }, [])
        })
        .finally(() => {
          if (this.disabledFeatures.configuredServices) {
            this.$store.dispatch('Services/clearCart')
          }
        })
    },
    removePromo(service) {
      this.promoCodes = this.promoCodes.filter(p => p.productUid !== service.productUid)
      // remove the promo code applied to the service order
      this.addPromoCodeToOrder(service.productUid, null)
      service.promoCode = ''
      service.promoCodeDesc = ''
      service.promoValidationStatus = this.STATUS_UNCHECKED
    },
    async deploy() {
      if (!this.canDeploy) return
      this.setPeriodicRefreshEnabled(false)
      this.deployingMessage = this.$t('checkout.deploying')
      this.deploying = true

      const serviceOrderId = localStorage.getItem(`_mpCartId_${this.companyUid}`)
      this.setDeployingCart({ serviceOrderUid: serviceOrderId, deploying: true })

      const promoCodes = {}
      this.promoCodes.forEach(pc => {
        promoCodes[pc.productUid] = pc.code
      })

      try {
        let deployData = null
        try {
          deployData = await sdk.instance.networkDesign().buy(this.orderConfigPayload)
        } catch (error) {
          this.notifyBad({
            title: `${this.$t('checkout.error-deploying')} - ${error.data?.message || this.$t('checkout.unknown-error')}`,
            duration: 0,
          })
          throw error
        }
        this.serviceList.forEach(service => {
          this.changeProvisioningStatus({ serviceUid: service.productUid, status: this.G_PROVISIONING_DESIGN_DEPLOY })
        })

        this.notifyGood({
          title: this.$t('checkout.deployed'),
        })

        this.setDeployingCart({ serviceOrderUid: serviceOrderId, deploying: false })

        this.deployingMessage = this.$t('checkout.deleting-configuration')
        await this.deleteCartFromServer({ serviceOrder: serviceOrderId, suppressSuccessMessages: true })
        this.clearCartFromLocalStorage()

        captureEvent('order-services.order-now.click', {
          services: this.displayList.map(service => ({
            productType: service.productType,
            price: service.price && service.price.monthlyRate * service.multiplier,
            currency: service.price?.currency,
          })),
        })

        // Set flag hasOrderedServices to true after first successful order of Direct Customer
        if (this.isDirectAccount && !this.hasOrderedServices) {
          this.setHasOrderedServicesTrue()
        }

        // Check if any IBM connections exist in the cart and display modal reminding users to confirm connection in IBM portal
        if (deployData.some(({ connectType }) => connectType === 'IBM')) {
          this.showIbmReminderModal()
        }

        this.deployingMessage = this.$t('checkout.refreshing-services')
        await this.getMyServices()

        const newPath = resolveServicesPage()
        if (newPath !== this.$route.path) {
          this.$router.push(newPath)
        }
      } catch (error) {
        captureSentryError(error)
      } finally {
        this.setPeriodicRefreshEnabled(true)
        this.setVisible(false)
        this.deploying = false
      }
    },
    minimumTerm(service) {
      return [G_PRODUCT_TYPE_MCR2, G_PRODUCT_TYPE_MVE, G_PRODUCT_TYPE_VXC].includes(service.productType) && service.term === 1 ? 0 : service.term
    },
    getIconFor(service) {
      // Since the section is on a v-show, it will try to load the icon while deploying and fail in the case of a service that is in the
      // process of being deployed. This is a workaround to prevent the icon from being loaded in that case.
      if (this.deploying) return ''

      const serviceDetails = this.findService(service.productUid)

      let icon = serviceDetails.productType

      if (serviceDetails.productType === this.G_PRODUCT_TYPE_VXC) {
        const aEndDetails = this.findPort(serviceDetails.aEnd.productUid)
        const bEndDetails = this.findPort(serviceDetails.bEnd.productUid)

        if (aEndDetails?.companyUid && bEndDetails?.companyUid) {
          const foreignEnd = [aEndDetails, bEndDetails].find(end => end.companyUid !== this.companyUid)

          if (foreignEnd?.connectType === 'TRANSIT') icon = 'MegaportInternet'
        }
      }

      return icon
    },
  },
}
</script>

<style lang="scss" scoped>
.service-profile {
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
  margin: 1rem 0;
}

.service-summary {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  padding: 1rem 0;
}

.service-details {
  border-top: 1px solid var(--border-color-base);
  padding: 1rem;
}

.notices {
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  margin: 1rem 0;
  padding: 1rem 2rem;
}

.prices {
  margin-left: auto;
  margin-right: 1.4rem;
  flex-shrink: 0;
}

svg.product-icon {
  display: inline-block;
  flex-shrink: 0;
  margin-block: 0;
  margin-inline: 1rem;
  width: 4em;
  height: 4em;
  color: var(--color-text-regular);
  fill: currentColor;
}

.width-130px {
  width: 130px;
}
</style>
